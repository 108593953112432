import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createSession } from '@/redux/slices/avatarSlices/avatarSlice'

import { levelOrder } from '@/helpers/constants'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '../Spinner'
import { BorderArticle } from '../BorderArticle'

import crownImage from '@/assets/images/cards/crown.svg'
import errorImage from '@/assets/images/popups/error-upload.svg'
import styles from './styles.module.scss'

export const PreviewCard = props => {
	const { name, image, due_date, mask, levelList, id } = props

	const { serverStatus } = useSelector(state => state.avatar)

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleClickLevel = async (item, id) => {
		const resultAction = await dispatch(
			createSession({ level: item.name, scenario_id: id })
		)

		if (createSession.fulfilled.match(resultAction)) {
			navigate(`/chat-game/${item.name}/${id}`, {
				state: { level: item },
			})
		} else {
			console.log("Don't create session!")
		}
	}

	return (
		<BorderArticle>
			<div className={styles.card}>
				{serverStatus === 'loading' && <Spinner width={40} height={40} />}

				{image && (
					<img className={styles.cardPreview} src={image} alt='preview-image' />
				)}

				{mask && (
					<div
						className={styles.maskPreview}
						style={{ background: `var(--${mask})` }}
					></div>
				)}

				{due_date && (
					<div className={styles.cardHead}>
						<img src={errorImage} alt='warning' />

						<RootDesc>
							<span>
								Until <b>{due_date}</b>
							</span>
						</RootDesc>
					</div>
				)}

				{name && <h2>{name}</h2>}

				{levelList && (
					<ul className={styles.cardFooter}>
						{levelOrder.map(item => (
							<li
								onClick={() => handleClickLevel(item, id)}
								key={item?.id}
								// style={
								// 	item?.name === 'open' || item?.name === 'success'
								// 		? { pointerEvents: 'all' }
								// 		: { pointerEvents: 'none' }
								// }
							>
								{/* {item?.name === 'success' && <img src={crownImage} alt='icon' />} */}

								<RootDesc>
									<span>
										<b
											style={
												item?.name === 'success'
													? { color: 'var(--blueLight)' }
													: {}
											}
										>
											{item?.name}
										</b>
									</span>
								</RootDesc>
							</li>
						))}
					</ul>
				)}
			</div>
		</BorderArticle>
	)
}
