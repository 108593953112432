import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { Overlay } from '@/components/layouts/Overlay'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import closeImg from '@/assets/images/popups/close-white.svg'
import play from '@/assets/images/popups/play.svg'
import pause from '@/assets/images/popups/pause.svg'
import rewind from '@/assets/images/popups/rewind.svg'
import settings from '@/assets/images/popups/settings.svg'
import sound from '@/assets/images/popups/sound.svg'
import fullscreen from '@/assets/images/popups/fullscreen.svg'

import videoPreview from '@/assets/images/general/video-fallback.png'

import styles from './styles.module.scss'

export const VideoPopup = ({ video, onClick }) => {
	const videoStream = '/video/video.mp4'

	const [volume, setVolume] = useState(0.2)
	const [timeLine, setTimeLine] = useState(0)
	const [isPlaying, setIsPlaying] = useState(false)
	const [videoDuration, setVideoDuration] = useState(0)
	const [currentTime, setCurrentTime] = useState(0)
	const [videoError, setVideoError] = useState(false)
	const [preview, setPreview] = useState(true)

	const volumeRef = useRef(null)
	const timeLineRef = useRef(null)
	const fullscreenRef = useRef(null)
	const playerRef = useRef(null)

	const handleVideoEnded = () => {
		setIsPlaying(false)
		setPreview(true)
		setCurrentTime(videoDuration)
		setTimeLine(1)
	}

	const handleVideoError = () => {
		setVideoError(true)
	}

	const handleProgress = progress => {
		setCurrentTime(progress.playedSeconds)
		setTimeLine(progress.playedSeconds / videoDuration)
	}

	const handleDuration = duration => {
		setVideoDuration(duration)
	}

	const handleVolumeChange = event => {
		setVolume(parseFloat(event.target.value))
	}

	const handleTimeLineChange = event => {
		const newTimeLine = parseFloat(event.target.value)

		setTimeLine(newTimeLine)

		if (playerRef.current) {
			const newTime = newTimeLine * videoDuration

			playerRef.current.seekTo(newTime)
			setCurrentTime(newTime)
		}
	}

	const handlePlay = () => {
		setIsPlaying(!isPlaying)
		setPreview(false)
	}

	const handleClickSound = () => {
		setVolume(volume > 0 ? 0 : 1)
	}

	const handleFullscreen = () => {
		const playerContainer = fullscreenRef.current

		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen()
			}
		} else {
			if (playerContainer.requestFullscreen) {
				playerContainer.requestFullscreen()
			}
		}
	}

	useEffect(() => {
		if (volumeRef.current) {
			const maxWidth = volumeRef.current.offsetWidth - 8
			const position = (volume / 1) * maxWidth

			volumeRef.current.style.setProperty('--i-position', `${position}rem`)
		}
	}, [volume])

	useEffect(() => {
		if (timeLineRef.current) {
			const maxWidth = timeLineRef.current.offsetWidth - 16
			const position = timeLine * maxWidth

			timeLineRef.current.style.setProperty('--i-position', `${position}rem`)
		}
	}, [timeLine])

	const formatTime = seconds => {
		const minutes = Math.floor(seconds / 60)
		const sec = Math.floor(seconds % 60)

		return `${minutes < 10 ? '0' : ''}${minutes}:${sec < 10 ? '0' : ''}${sec}`
	}

	return (
		<Overlay color={'rgba(65, 64, 64, 0.6)'}>
			<div className={styles.videoPopup}>
				<div onClick={onClick} className={styles.close}>
					<img src={closeImg} alt='close' />
				</div>

				<h2>{video.title}</h2>

				<div
					ref={fullscreenRef}
					style={{ background: 'var(--greylight)' }}
					className={styles.videoPlayer}
				>
					<div onClick={handlePlay} className={styles.video}>
						<img
							style={preview ? { zIndex: '1' } : { zIndex: '-2' }}
							src={video.image}
							alt='preview'
						/>

						{!preview && !videoError && videoStream && (
							<ReactPlayer
								ref={playerRef}
								playing={isPlaying}
								url={videoStream}
								volume={volume}
								width={'100%'}
								height={'100%'}
								onEnded={handleVideoEnded}
								onProgress={handleProgress}
								onDuration={handleDuration}
								onError={handleVideoError}
							/>
						)}

						{(videoError || !videoStream) && (
							<RootDesc>
								<span className='text-[var(--white)]'>
									Failed to load video
								</span>
							</RootDesc>
						)}
					</div>

					{videoStream && !videoError && (
						<>
							<div
								onClick={handlePlay}
								className={styles.videoPlay}
								style={isPlaying ? { zIndex: '-1' } : { zIndex: '2' }}
							>
								<RootIcon id={'play'} />
							</div>

							<div
								style={preview ? { opacity: '0' } : { opacity: '1' }}
								className={styles.videoControls}
							>
								<div className='flex items-center gap-[16rem]'>
									{isPlaying ? (
										<img
											onClick={handlePlay}
											className='w-[30rem] h-[38rem]'
											src={pause}
											alt='pause'
										/>
									) : (
										<img
											onClick={handlePlay}
											className='w-[30rem] h-[32rem]'
											src={play}
											alt='play'
										/>
									)}

									<img
										className='w-[24rem] h-[14rem]'
										src={rewind}
										alt='rewind'
									/>
								</div>

								<div className='flex items-center gap-[16rem]'>
									<div className={styles.time}>
										<time>{formatTime(currentTime)}</time>
									</div>

									<div ref={timeLineRef} className={styles.timeLine}>
										<label htmlFor='time-line'>
											<input
												min={0}
												max={1}
												step={0.01}
												id='time-line'
												type='range'
												value={timeLine}
												onChange={handleTimeLineChange}
											/>
											<div
												className={styles.bar}
												style={{ width: 'calc(var(--i-position) + 8rem)' }}
											>
												<i
													className={styles.thumb}
													style={{ left: 'var(--i-position)' }}
												></i>
											</div>
										</label>
									</div>

									<div className={styles.time}>
										<time>{formatTime(videoDuration)}</time>
									</div>
								</div>

								<div className='flex items-center gap-[8rem]'>
									<img
										className='w-[32rem] h-[32rem]'
										src={settings}
										alt='settings'
									/>

									<img
										onClick={handleClickSound}
										className='w-[32rem] h-[32rem]'
										src={sound}
										alt='sound'
									/>

									<div ref={volumeRef} className={styles.volume}>
										<label htmlFor='volume'>
											<input
												min={0}
												max={1}
												step={0.05}
												id='volume'
												type='range'
												value={volume}
												onChange={handleVolumeChange}
											/>
											<div
												className={styles.bar}
												style={{ width: 'calc(var(--i-position) + 4rem)' }}
											>
												<i
													className={styles.thumb}
													style={{ left: 'var(--i-position)' }}
												></i>
											</div>
										</label>
									</div>

									<img
										onClick={handleFullscreen}
										className='w-[34rem] h-[20rem]'
										src={fullscreen}
										alt='fullscreen'
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</Overlay>
	)
}
