import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Header } from '@/components/layouts/Header'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Footer } from '@/components/layouts/Footer'
import { H2 } from '@/components/ui/titles/H2'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Logo } from '@/components/ui/general/Logo'

import styles from './styles.module.scss'

export const Privacy = () => {
	const { auth } = useSelector(state => state.candidate)
	const location = useLocation()

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [location])

	return (
		<>
			<div className='container-big'>
				<div className={styles.privacyWrapper}>
					{auth ? (
						<Header />
					) : (
						<div className={styles.privacyHead}>
							<Logo />
						</div>
					)}

					<BorderArticle>
						<div className={styles.privacy}>
							<BackButton />

							<div className={styles.privacyTitle}>
								<H3>Privacy Policy for&nbsp;Ava: AI-driven simulations</H3>
							</div>

							<h1>Privacy Policy</h1>

							<RootDesc>
								<span>Last updated: October&nbsp;11, 2024</span>
								<span>
									This Privacy Policy outlines how we collect, store, use,
									disclose, and protect your personal information when you use
									the Avatar AI Simulation Platform ("Platform"). By accessing
									or using the Platform, you agree to the terms of this Privacy
									Policy. It also explains your rights in relation to your
									personal data and how to contact us in the event you have a
									complaint.
								</span>
								<span>
									This website is operated by Vivanty SA, a public limited
									company registered in Belgium (we, us, our).
								</span>
								<span>
									When we collect and use your personal data we act as a Data
									Controller. We are subject to the UK General Data Protection
									Regulation (UK GDPR). We are also subject to the EU General
									Data Protection Regulation (EU GDPR) in relation to the
									services we provide to individuals who are based in the
									European Economic Area (EEA).
								</span>
								<span>
									“Personal data” means any information relating to an
									identified or identifiable individual. We may also refer to
									the “data subject” which means the individual who the personal
									data relates to.
								</span>
							</RootDesc>

							<H2>1.&nbsp;Information We&nbsp;Collect</H2>

							<h4>1.1&nbsp;Personal Information</h4>

							<RootDesc>
								<span>
									We collect personal information that you provide directly to
									us when you register for an account, including but not limited
									to:
								</span>

								<ul>
									<li>Name</li>
									<li>Email address</li>
									<li>Job title</li>
									<li>Company name</li>
									<li>Country and City</li>
								</ul>
							</RootDesc>

							<h4>1.2&nbsp;Usage Data</h4>

							<RootDesc>
								<span>
									We automatically collect certain information when you use the
									Platform, such as:
								</span>

								<ul>
									<li>Your username and password</li>
									<li>IP address</li>
									<li>Operating system, browser type and version</li>
									<li>Pages visited on the Platform</li>
									<li>Time and date of visits</li>
									<li>Time spent on those pages</li>
									<li>Type of device and unique device identifiers</li>
									<li>Results of simulations</li>
								</ul>
							</RootDesc>

							<h4>1.3&nbsp;Cookies and&nbsp;Tracking Technologies</h4>

							<RootDesc>
								<span>
									We use cookies and similar tracking technologies to monitor
									activity on our Platform and store certain information. You
									can instruct your browser to refuse all cookies or to indicate
									when a cookie is being sent.
								</span>
							</RootDesc>

							<H2>2.&nbsp;How&nbsp;We Use Your Information</H2>

							<RootDesc>
								<span>
									We may use the information we collect for various purposes,
									including:
								</span>

								<ul>
									<li>To provide and maintain our Platform</li>
									<li>To notify you about changes to our Platform</li>
									<li>
										To allow you to participate in interactive features of our
										Platform when you choose to do so
									</li>
									<li>To provide customer support</li>
									<li>
										To gather analysis or valuable information so that we can
										improve our Platform
									</li>
									<li>To monitor the usage of our Platform</li>
									<li>To detect, prevent, and address technical issues</li>
									<li>
										To send you newsletters, marketing communications, or other
										information that may be of interest to you (with your
										consent)
									</li>
									<li>
										The Platform uses the data from the simulations to provide
										feedback on the performance of the simulated activities.
										This assists in enhancing the user experience and improving
										future simulations.
									</li>
								</ul>
							</RootDesc>

							<H2>
								3.&nbsp;Legal Basis for&nbsp;Processing Personal&nbsp;Data
							</H2>

							<RootDesc>
								<span>
									Under data protection law, we must have a legal basis for
									processing your personal data.
								</span>

								<span>
									We process your personal data based on the following legal
									grounds:
								</span>

								<ul>
									<li>
										<b>Consent:</b> When you provide your explicit consent for
										us to process your personal data for specific purposes.
									</li>
									<li>
										<b>Contractual Necessity:</b> When processing is necessary
										for the performance of a contract with you.
									</li>
									<li>
										<b>Legal Obligation:</b> When we need to comply with a legal
										obligation.
									</li>
									<li>
										<b>Legitimate Interests:</b> We may process your personal
										data when it is necessary for our legitimate interests or
										those of a third party, as long as your interests and
										fundamental rights do not outweigh those interests. Our
										legitimate interest includes using your personal data for
										marketing purposes, which typically does not require your
										consent. However, when consent is required, we will request
										it separately and clearly.
									</li>
								</ul>

								<span>
									We will send you notifications about new courses, simulations
									and reminders to complete the simulation by the due date. We
									will also send you our newsletters where you have told us that
									you would like to receive this (i.e. where you have given your
									consent).
								</span>

								<span>
									You have the right to opt-out of receiving marketing
									communications at any time by contacting us at info@vivanti.eu
									or using the “unsubscribe” link in emails.
								</span>

								<span>
									We are committed to safeguarding your personal data and will
									never sell it to any third party, whether within or outside
									our group, for marketing purposes.
								</span>
							</RootDesc>

							<H2>4.&nbsp;Disclosure of&nbsp;Your Information</H2>

							<RootDesc>
								<span>
									We may share your personal information in the following
									situations:
								</span>

								<ul>
									<li>
										<b>With Service Providers:</b> We may share your personal
										information with service providers who assist us in
										operating our Platform or providing services.
									</li>
									<li>
										<b>With Affiliates:</b> We may share your information with
										our affiliates, in which case we will require those
										affiliates to honor this Privacy Policy.
									</li>
									<li>
										<b>With Consent:</b> We may disclose your personal
										information for any other purpose with your consent.
									</li>
									<li>
										<b>For Legal Reasons:</b> We may disclose your personal
										information if required by law or in response to valid
										requests by public authorities.
									</li>
								</ul>
							</RootDesc>

							<H2>5.&nbsp;Data Security</H2>

							<RootDesc>
								<span>
									We take the security of your personal information seriously
									and implement appropriate technical and organisational
									measures designed to protect it from unauthorised access, use,
									alteration, or destruction. However, please be aware that no
									method of transmission over the Internet or method of
									electronic storage is 100% secure.
								</span>
							</RootDesc>

							<H2>6.&nbsp;Data Retention</H2>

							<RootDesc>
								<span>
									We will retain your personal information only for as long as
									is necessary to fulfill the purposes outlined in this Privacy
									Policy or as required by law. When we no longer need your
									personal data, we will securely delete it.
								</span>
							</RootDesc>

							<H2>7.&nbsp;Your Rights Under&nbsp;GDPR</H2>

							<RootDesc>
								<span>
									As a user in the European Economic Area (EEA), you have
									certain rights regarding your personal data:
								</span>

								<ul>
									<li>
										<b>Right to Access:</b> You have the right to request copies
										of your personal data.
									</li>
									<li>
										<b>Right to Rectification:</b> You have the right to request
										that we correct any inaccurate or incomplete information.
									</li>
									<li>
										<b>Right to Erasure:</b> You have the right to request that
										we delete your personal data under certain conditions.
									</li>
									<li>
										<b>Right to Restrict Processing:</b> You have the right to
										request that we restrict the processing of your personal
										data under certain conditions.
									</li>
									<li>
										<b>Right to Data Portability:</b> You have the right to
										request that we transfer the data that we have collected
										about you to another organisation or directly to you under
										certain conditions.
									</li>
									<li>
										<b>Right to Object:</b> You have the right to object to our
										processing of your personal data under certain conditions.
									</li>
								</ul>

								<br />
								<br />

								<span>
									To exercise these rights, please contact us using the contact
									details specifies in Section 11 of this Privacy Policy.
								</span>
							</RootDesc>

							<H2>8.&nbsp;International Data Transfers</H2>

							<RootDesc>
								<span>
									If we transfer your personal data outside of the European
									Economic Area (EEA), we will ensure that adequate safeguards
									are in place as required by GDPR. This may include using
									Standard Contractual Clauses approved by the European
									Commission.
								</span>
							</RootDesc>

							<H2>9.&nbsp;Data Breach Notification</H2>

							<RootDesc>
								<span>
									In the event of a data breach affecting your personal data, we
									will notify you and any applicable regulatory authority within
									72 hours if required by law.
								</span>
							</RootDesc>

							<H2>10.&nbsp;Changes to&nbsp;This Privacy Policy</H2>

							<RootDesc>
								<span>
									We may update our Privacy Policy from time to time. We will
									notify you of any changes by posting the new Privacy Policy on
									this page with an updated effective date at the top. You are
									advised to review this Privacy Policy periodically for any
									changes.
								</span>
							</RootDesc>

							<H2>11.&nbsp;Contact&nbsp;Us</H2>

							<RootDesc>
								<span>
									If you have any questions about this Privacy Policy or wish to
									exercise any of your rights regarding your personal data,
									please contact us at{' '}
									<a href='mailto:info@vivanti.eu' target='_blank'>
										info@vivanti.eu
									</a>
								</span>
							</RootDesc>
						</div>
					</BorderArticle>
				</div>
			</div>

			<Footer />
		</>
	)
}
