import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import VideosService from '@/services/VideosService'

export const getEditVideo = createAsyncThunk('video/edit', async ({ id }) => {
	try {
		const response = await VideosService.viewVideo({ id })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

export const updateVideo = createAsyncThunk(
	'video/update',
	async ({ id, video }) => {
		try {
			const response = await VideosService.updateVideo({ id, video })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isVideoEdit: false,
	saveVideoEdit: false,
	error: '',
	serverStatus: '',
	editVideo: null,
	initialEditVideo: null,
}

const editVideoSlice = createSlice({
	name: 'edit-video',
	initialState,
	reducers: {
		setIsVideoEdit(state, action) {
			state.isVideoEdit = action.payload
		},
		setSaveVideoEdit(state, action) {
			state.saveVideoEdit = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setEditVideo(state, action) {
			state.editVideo = action.payload
		},
		clearEditVideo() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getEditVideo.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getEditVideo.fulfilled, (state, action) => {
				state.editVideo = action.payload
				state.initialEditVideo = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getEditVideo.rejected, state => {
				state.error = 'Don`t get edit video!'
				state.serverStatus = 'error'
			})

			.addCase(updateVideo.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(updateVideo.fulfilled, (state, action) => {
				state.editVideo = action.payload
				state.initialEditVideo = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(updateVideo.rejected, state => {
				state.error = 'Don`t update video!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setEditVideo,
	setIsVideoEdit,
	setSaveVideoEdit,
	setError,
	setServerStatus,
	clearEditVideo,
} = editVideoSlice.actions
export default editVideoSlice.reducer
