export const levelOrder = [
	{ id: 0, name: 'easy', remainingTime: 600000 }, // 10 minuts = 600000 }
	{ id: 1, name: 'medium', remainingTime: 480000 }, // 8 minuts = 480000 }
	{ id: 2, name: 'hard', remainingTime: 300000 }, // 5 minuts = 300000 }
]

export const adminBarList = [
	{ id: 0, iconId: 'dashboard', name: 'Dashboard', link: 'dashboard' },
	{ id: 1, iconId: 'users', name: 'Users', link: 'users' },
	{ id: 2, iconId: 'scenarios', name: 'Call Simulations', link: 'scenarios' },
	{ id: 3, iconId: 'products', name: 'Product Bootcamp', link: 'products' },
	{ id: 4, iconId: 'library', name: 'Training Library', link: 'videos' },
	{ id: 5, iconId: 'analytics', name: 'Session History', link: 'analytics' },
]

export const roleList = [
	{ id: 0, name: 'Admin' },
	{ id: 1, name: 'Manager' },
	{ id: 2, name: 'Representative' },
]

export const simulationsViewList = [
	{
		id: 0,
		title: 'Call Simulations',
		link: 'call',
	},
	{
		id: 1,
		title: 'Product Bootcamp',
		link: 'product',
	},
	{
		id: 2,
		title: 'Training Library',
		link: 'videos',
	},
]

export const sessionTableList = [
	{
		id: 0,
		name: 'Call Simulations',
	},
	{
		id: 1,
		name: 'Product Bootcamp',
	},
]

export const footerLinkList = [
	{
		id: 0,
		name: 'Privacy Policy',
		link: '/privacy',
	},
	{
		id: 1,
		name: 'Terms of Service',
		link: '/terms',
	},
]

export const coverList = [
	{ id: 0, name: 'peachGr' },
	{ id: 1, name: 'violetGr' },
	{ id: 2, name: 'pinkGr' },
]

export const descriptionLevelList = [
	{
		id: 0,
		text: 'In this level, you’ll interact with a virtual doctor who is generally receptive and has a relatively free schedule. Try to convince them to listen to you. You have 10 minutes to present your information and answer any straightforward questions. This level is designed to help you get comfortable with the basic script and interaction protocols.',
	},
	{
		id: 1,
		text: 'In this level, you’ll encounter a virtual doctor who is moderately busy and somewhat hesitant to engage in dialogue. You’ll need to use convincing arguments to get the doctor’s attention. You have 8 minutes to complete the visit. This level aims to improve your persuasive skills and ability to handle detailed product-related queries.',
	},
	{
		id: 2,
		text: 'In this challenging level, the virtual doctor is very busy and highly skeptical. You have only 5 minutes to complete the visit and must be prepared to answer complex questions with in-depth knowledge, including references to scientific studies and clinical trials. This level tests your comprehensive understanding of the product and your persuasive communication skills under high-pressure scenarios.',
	},
]
