export const statusVariant = score => {
	switch (true) {
		case !score:
			return {
				grade: ``,
				description: ``,
			}

		case score === 0:
			return {
				grade: `Unacceptable`,
				description: `We noticed some major issues this time. Let’s discuss how we can support you to get back on track.`,
			}

		case score >= 1 && score <= 3:
			return {
				grade: `Poor`,
				description: `It seems like there were some challenges this time, but don’t get discouraged. Let's work together to overcome them!`,
			}

		case score >= 4 && score <= 5:
			return {
				grade: `Fair`,
				description: `There are a few areas where improvements can be made, but you're on the right path—keep pushing!`,
			}

		case score >= 6 && score <= 7:
			return {
				grade: `Good`,
				description: `You're doing a solid job, and with just a bit more focus, you'll hit excellence in no time!`,
			}

		case score >= 8 && score <= 10:
			return {
				grade: `Excellent`,
				description: `You've truly excelled! Your dedication and effort really shine through—keep up the amazing work!`,
			}

		default:
			return {
				grade: ``,
				description: ``,
			}
	}
}

export const capitalize = str => {
	if (!str) return

	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isEqual = (editObj, currentObj) => {
	if (!editObj || !currentObj) return

	return Object.keys(editObj).every(key => editObj[key] === currentObj[key])
}

export const isValidEmail = email => {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

	return emailPattern.test(email)
}
