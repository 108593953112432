import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersService from '@/services/UsersService'

export const getEditUser = createAsyncThunk('user/edit', async ({ id }) => {
	try {
		const response = await UsersService.viewUser({ id })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

export const updateUser = createAsyncThunk(
	'user/update',
	async ({ id, user }) => {
		try {
			const response = await UsersService.updateUser({ id, user })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isUserEdit: false,
	saveUserEdit: false,
	error: '',
	serverStatus: '',
	editUser: null,
	initialEditUser: null,
}

const editUserSlice = createSlice({
	name: 'edit-user',
	initialState,
	reducers: {
		setIsUserEdit(state, action) {
			state.isUserEdit = action.payload
		},
		setSaveUserEdit(state, action) {
			state.saveUserEdit = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setEditUser(state, action) {
			state.editUser = action.payload
		},
		clearEditUser() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getEditUser.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getEditUser.fulfilled, (state, action) => {
				state.editUser = action.payload
				state.initialEditUser = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getEditUser.rejected, state => {
				state.error = 'Don`t get edit user!'
				state.serverStatus = 'error'
			})

			.addCase(updateUser.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.editUser = action.payload
				state.initialEditUser = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(updateUser.rejected, state => {
				state.error = 'Don`t update user!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setEditUser,
	setIsUserEdit,
	setSaveUserEdit,
	setError,
	setServerStatus,
	clearEditUser,
} = editUserSlice.actions
export default editUserSlice.reducer
