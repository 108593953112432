import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SessionsService from '@/services/SessionsService'

export const viewSession = createAsyncThunk('session/view', async ({ id }) => {
	try {
		const response = await SessionsService.viewSession({ id })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	session: null,
	error: '',
	serverStatus: '',
}

const viewSessionSlice = createSlice({
	name: 'view-session',
	initialState,
	reducers: {
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearViewSession() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(viewSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewSession.fulfilled, (state, action) => {
				state.session = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewSession.rejected, (state, action) => {
				state.error = 'Don`t view session!'
				state.serverStatus = 'error'
			})
	},
})

export const { clearViewSession, setError, setServerStatus } =
	viewSessionSlice.actions
export default viewSessionSlice.reducer
