import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	resetUpload,
	uploadFile,
} from '@/redux/slices/uploadSlices/uploadFileSlice'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { DropZone } from '@/components/ui/general/DropZone'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { FormError } from '@/components/ui/general/FormError'

import downloadImage from '@/assets/images/popups/download.svg'
import styles from './styles.module.scss'

const UploadSection = ({ title, description, accept, type }) => {
	const dispatch = useDispatch()

	const [file, setFile] = useState(null)
	const controllerRef = useRef(null)

	const { progress, uploading, error } = useSelector(state => state.upload)

	const uploadFileHandler = useCallback(file => {
		if (file) {
			setFile(file)
			dispatch(resetUpload())
		} else {
			console.error('File is undefined!')
		}
	}, [])

	const handleClickCancel = () => {
		if (controllerRef.current) {
			controllerRef.current.abort()

			dispatch(resetUpload())
			setFile(null)
		} else return
	}

	const handleSendFile = () => {
		if (file) {
			controllerRef.current = new AbortController()

			dispatch(uploadFile({ file, controller: controllerRef.current }))
		} else {
			console.error('No file to upload!')
		}
	}

	useEffect(() => {
		return () => {
			dispatch(resetUpload())
			setFile(null)
		}
	}, [])

	return (
		<div className={styles.upload}>
			<H3>{title}</H3>

			<div className={styles.uploadDesc}>{description}</div>

			<DropZone
				maxFiles={1}
				accept={accept}
				onUpload={uploadFileHandler}
				size={50000000}
				type={type}
			/>

			{file && (
				<div className={styles.uploadInfo}>
					<div className={styles.fileInfo}>
						{uploading && (
							<div
								style={progress === 100 ? { animation: 'none' } : {}}
								className={styles.spinner}
							>
								{error ? (
									<RootIcon width={16} height={16} id={'error-upload'} />
								) : (
									<RootIcon
										width={16}
										height={16}
										id={progress === 100 ? 'success-upload' : 'spinner-upload'}
									/>
								)}
							</div>
						)}

						<div className={styles.fileContent}>
							<div key={file?.name}>
								<SmallDesc>
									<span>{`${file?.name} ${Math.round(
										file?.size / (1024 * 1024)
									)}MB`}</span>
								</SmallDesc>
							</div>

							{uploading && (
								<div className={styles.progressBarWrapper}>
									<div className={styles.progressBar}>
										<i
											style={{
												background: error ? `var(--red)` : `var(--violet)`,
												width: `${progress}%`,
											}}
										/>
									</div>

									{progress !== 100 && (
										<div onClick={handleClickCancel}>
											<RootIcon width={16} height={16} id={'cancel-upload'} />
										</div>
									)}

									{progress === 100 && error && (
										<div onClick={handleClickCancel}>
											<RootIcon width={16} height={16} id={'refresh-upload'} />
										</div>
									)}
								</div>
							)}
						</div>
					</div>

					{error && (
						<FormError
							error={'Error uploading! Please, try again later!'}
							bottom={100}
						/>
					)}

					<RootButton
						onClick={handleSendFile}
						txt={'Save image'}
						disabled={uploading}
					/>
				</div>
			)}
		</div>
	)
}

export const UploadFileProgressPopup = ({ type }) => {
	const sampleFile = '/documents/sample_users.csv'

	if (type === 'csv') {
		return (
			<UploadSection
				title='Upload Users'
				description={
					<ol>
						<li>
							<RootDesc>
								<b>Download the sample file</b>&nbsp;
								<span>to ensure the correct format.</span>
								<br />
								<a href={sampleFile} download>
									<img src={downloadImage} alt='download' />
									<span className='text-[var(--grey)]'>template.csv</span>
								</a>
								<br />
								<br />
							</RootDesc>
						</li>

						<li>
							<RootDesc>
								<b>Fill in the required information</b>&nbsp;
								<span>in the file.</span>
							</RootDesc>
						</li>

						<li>
							<RootDesc>
								<b>Upload the completed file</b>&nbsp;
								<span>by Drag & Drop or clicking the button below.</span>
							</RootDesc>
						</li>
					</ol>
				}
				accept={{ 'text/csv': ['.csv'] }}
				type={'csv'}
			/>
		)
	} else {
		return (
			<UploadSection
				title='Upload Your Profile Photo'
				description={
					<RootDesc>
						<span>
							Please select and upload a photo that will be displayed on your
							profile.
						</span>
					</RootDesc>
				}
				accept={{ 'image/*': ['.png', '.jpg'] }}
				type={'jpg, png'}
			/>
		)
	}
}
