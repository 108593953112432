import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import styles from './styles.module.scss'

export const RootCheckbox = props => {
	const { checked, onChange, label, disabled, fontSize } = props

	return (
		<label className={styles.rootCheckbox}>
			<input
				type='checkbox'
				checked={checked}
				onChange={onChange}
				aria-label={label}
				disabled={disabled}
			/>

			<i className={styles.mark}></i>

			{label && (
				<SmallDesc>
					<span
						style={
							fontSize ? { fontSize: `${fontSize}rem` } : { fontSize: `14rem` }
						}
					>
						{label}
					</span>
				</SmallDesc>
			)}
		</label>
	)
}
