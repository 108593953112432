import React from 'react'
import { useNavigate } from 'react-router-dom'

import { RootIcon } from '../../icons/RootIcon'
import { RootDesc } from '../../descriptions/RootDesc'

import styles from './styles.module.scss'

export const BackButton = () => {
	const navigate = useNavigate()

	return (
		<button className={styles.backBtn} onClick={() => navigate(-1)}>
			<RootIcon width={17} height={17} id={'arrow-back'} />

			<RootDesc>
				<span>Back</span>
			</RootDesc>
		</button>
	)
}
