import React from 'react'
import { coverList } from '@/helpers/constants'

import styles from './styles.module.scss'

export const MaskList = ({ onClickCover }) => {
	return (
		<ul className={styles.maskList}>
			{coverList.map(item => (
				<li
					onClick={() => onClickCover(item.name)}
					style={{ background: `var(--${item.name})` }}
					key={item.id}
				></li>
			))}
		</ul>
	)
}
