import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ScenariosService from '@/services/ScenariosService'

export const getScenarios = createAsyncThunk(
	'scenarios',
	async ({ page, size }) => {
		try {
			const response = await ScenariosService.getScenarios({ page, size })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

export const getAllScenarios = createAsyncThunk('all-scenarios', async () => {
	try {
		const response = await ScenariosService.getAllScenarios()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	scenarios: [],
	page: 1,
	size: 5,
	sort: 'Name',
	totalPages: 1,
	error: '',
	serverStatus: '',
}

const scenariosSlice = createSlice({
	name: 'scenarios',
	initialState,
	reducers: {
		setPage(state, action) {
			state.page = action.payload
		},
		setSize(state, action) {
			state.size = action.payload
		},
		setSort(state, action) {
			state.sort = action.payload
		},
		setTotalPages(state, action) {
			state.totalPages = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearScenarios() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getScenarios.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getScenarios.fulfilled, (state, action) => {
				state.scenarios = action.payload.data
				state.totalPages = action.payload.total
					? Math.ceil(action.payload.total / state.size)
					: state.totalPages
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getScenarios.rejected, state => {
				state.error = 'Don`t get scenarios!'
				state.serverStatus = 'error'
			})

			.addCase(getAllScenarios.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getAllScenarios.fulfilled, (state, action) => {
				state.scenarios = action.payload.data
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getAllScenarios.rejected, state => {
				state.error = 'Don`t get all scenarios!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setPage,
	setSort,
	clearScenarios,
	setTotalPages,
	setError,
	setSize,
	setServerStatus,
} = scenariosSlice.actions
export default scenariosSlice.reducer
