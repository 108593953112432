import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProductsService from '@/services/ProductsService'

export const getEditProduct = createAsyncThunk(
	'product/edit',
	async ({ id }) => {
		try {
			const response = await ProductsService.viewProduct({ id })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

export const updateProduct = createAsyncThunk(
	'product/update',
	async ({ id, product }) => {
		try {
			const response = await ProductsService.updateProduct({ id, product })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isProductEdit: false,
	saveProductEdit: false,
	error: '',
	serverStatus: '',
	editProduct: null,
	initialEditProduct: null,
}

const editProductSlice = createSlice({
	name: 'edit-product',
	initialState,
	reducers: {
		setIsProductEdit(state, action) {
			state.isProductEdit = action.payload
		},
		setSaveProductEdit(state, action) {
			state.saveProductEdit = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setEditProduct(state, action) {
			state.editProduct = action.payload
		},
		clearEditProduct() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getEditProduct.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getEditProduct.fulfilled, (state, action) => {
				state.editProduct = action.payload
				state.initialEditProduct = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getEditProduct.rejected, state => {
				state.error = 'Don`t get edit product!'
				state.serverStatus = 'error'
			})

			.addCase(updateProduct.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(updateProduct.fulfilled, (state, action) => {
				state.editProduct = action.payload
				state.initialEditProduct = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(updateProduct.rejected, state => {
				state.error = 'Don`t update product!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setEditProduct,
	setIsProductEdit,
	setSaveProductEdit,
	setError,
	setServerStatus,
	clearEditProduct,
} = editProductSlice.actions
export default editProductSlice.reducer
