import React from 'react'
import styles from './styles.module.scss'
import { ControlButton } from '@/components/ui/buttons/ControlButton'

export const AvatarErrorPopup = props => {
	const { onClickTryAgain, onClickExit } = props

	return (
		<div className={styles.deletePopup}>
			<h2>
				An error occurred while loading the avatar. <br />
				Please try again later or contact our support team.
			</h2>

			<div className={styles.buttons}>
				<ControlButton
					color='var(--blue)'
					txt={'Try Again'}
					onClick={onClickTryAgain}
				/>

				<ControlButton
					color='var(--green)'
					txt={'Exit simulation'}
					onClick={onClickExit}
				/>
			</div>
		</div>
	)
}
