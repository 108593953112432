import $api from '../http'

export default class ScenariosService {
	static async getScenarios({ page, size }) {
		return $api.get(`/api/v1/scenarios?page=${page}&page_size=${size}`)
	}

	static async getAllScenarios() {
		return $api.get(`/api/v1/scenarios`)
	}

	static async getProducts() {
		return $api.get(`/api/v1/products/ids`)
	}

	static async viewScenario({ id }) {
		return $api.get(`/api/v1/scenarios/${id}`)
	}

	static async createScenario({ scenario }) {
		return $api.post(
			`/api/v1/scenarios`,
			{ ...scenario },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}

	static async updateScenario({ id, scenario }) {
		return $api.patch(
			`/api/v1/scenarios/${id}`,
			{ ...scenario },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}
}
