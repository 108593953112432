import IconSprite from '@/assets/images/icons-sprites.svg'

export const RootIcon = props => {
	const { onClickIcon, width, height, id } = props

	if (onClickIcon) {
		return (
			<svg
				style={{
					transition: 'all .15s linear',
					width: `${width}rem`,
					height: `${height}rem`,
				}}
				onClick={() => onClickIcon()}
			>
				<use href={IconSprite + '#' + id}></use>
			</svg>
		)
	} else {
		return (
			<svg
				style={{
					transition: 'all .15s linear',
					width: `${width}rem`,
					height: `${height}rem`,
				}}
			>
				<use href={IconSprite + '#' + id}></use>
			</svg>
		)
	}
}
