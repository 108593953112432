import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { H3 } from '@/components/ui/titles/H3'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { useDispatch, useSelector } from 'react-redux'

import {
	clearCandidate,
	setData,
	setReason,
	setError,
	setServerStatus,
} from '@/redux/slices/changeRequestSlice'
import { ControlButton } from '@/components/ui/buttons/ControlButton'

export const ChangeRequestPopup = () => {
	const { data, reason, error, serverStatus } = useSelector(
		state => state.change
	)
	const dispatch = useDispatch()

	const handleSubmit = e => {
		e.preventDefault()
		dispatch(setError(''))

		if (!data || !reason) {
			dispatch(setError('Пожалуйста, заполните все поля.'))
			return
		}

		dispatch(setServerStatus('loading'))

		const timer = setTimeout(() => {
			dispatch(setServerStatus(''))
			dispatch(clearCandidate())
		}, 3000)

		return () => {
			clearTimeout(timer)
		}
	}

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				dispatch(setError(''))
			}, 3000)

			return () => clearTimeout(timer)
		}
	}, [error, dispatch])

	return (
		<div className={styles.change}>
			<H3>Change Request</H3>

			<form onSubmit={handleSubmit} className={styles.changeForm}>
				<div className={styles.input}>
					<RootInput
						width={500}
						label={
							<>
								Which data do <br /> you need to <br /> change?
							</>
						}
						type={'text'}
						value={data}
						required={true}
						onChange={e => dispatch(setData(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.input}>
					<RootInput
						width={500}
						label={
							<>
								Reason for the <br /> change
							</>
						}
						type={'text'}
						value={reason}
						required={true}
						onChange={e => dispatch(setReason(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<ControlButton
					color={
						!data || !reason || serverStatus === 'loading'
							? 'var(--grey)'
							: 'var(--blue)'
					}
					txt={'Send Request'}
					type={'submit'}
					disabled={!data || !reason || serverStatus === 'loading'}
				/>
			</form>
		</div>
	)
}
