import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import VideosService from '@/services/VideosService'

export const initialCreateVideo = {
	name: '',
	product_ids: [],
	country_id: null,
	description: '',
	product_line_id: null,
}

export const postCreateVideo = createAsyncThunk(
	'video/create',
	async ({ video }) => {
		try {
			await VideosService.createVideo({ video })
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isVideoCreate: false,
	saveVideoCreate: false,
	error: '',
	serverStatus: '',
	createVideo: initialCreateVideo,
}

const createVideoSlice = createSlice({
	name: 'create-video',
	initialState,
	reducers: {
		setIsVideoCreate(state, action) {
			state.isVideoCreate = action.payload
		},
		setSaveVideoCreate(state, action) {
			state.saveVideoCreate = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setCreateVideo(state, action) {
			state.createVideo = action.payload
		},
		clearCreateVideo() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(postCreateVideo.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(postCreateVideo.fulfilled, (state, action) => {
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(postCreateVideo.rejected, (state, action) => {
				state.error = 'Don`t create video!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setIsVideoCreate,
	setCreateVideo,
	setSaveVideoCreate,
	setError,
	setServerStatus,
	clearCreateVideo,
} = createVideoSlice.actions
export default createVideoSlice.reducer
