import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Header } from '@/components/layouts/Header'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Footer } from '@/components/layouts/Footer'
import { H2 } from '@/components/ui/titles/H2'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Logo } from '@/components/ui/general/Logo'

import styles from './styles.module.scss'

export const Terms = () => {
	const { auth } = useSelector(state => state.candidate)
	const location = useLocation()

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [location])

	return (
		<>
			<div className='container-big'>
				<div className={styles.privacyWrapper}>
					{auth ? (
						<Header />
					) : (
						<div className={styles.privacyHead}>
							<Logo />
						</div>
					)}

					<BorderArticle>
						<div className={styles.privacy}>
							<BackButton />

							<div className={styles.privacyTitle}>
								<H3>
									Terms and&nbsp;Conditions for&nbsp;Avatar AI&nbsp;Simulation
									Platform
								</H3>
							</div>

							<h1>Terms and Conditions</h1>

							<RootDesc>
								<span>
									Please read and review the following important Terms and
									Conditions before starting to use the Avatar AI Simulation
									Platform. Ensure they include everything you expect and
									nothing that you are not willing to agree to.
								</span>

								<span>These Terms and Conditions (“Terms”) set out:</span>

								<ul>
									<li>Your legal rights and responsibilities;</li>
									<li>Our legal rights and responsibilities;</li>
									<li>Certain key information required by law.</li>
								</ul>

								<span>In these Terms:</span>

								<ul>
									<li>
										“we”, “us” or “our” means Vivanti SA, a public limited
										company registered in Belgium. We operate via our website{' '}
										<a href='http://vivanti.eu' target='_blank'>
											http://vivanti.eu
										</a>{' '}
										(our “Site”);
									</li>
									<li>
										“Avatar AI Simulation Platform” means a software application
										that enables a user to schedule and participate in live
										simulations operate via{' '}
										<a href='http://ava.vivanti.eu' target='_blank'>
											http://ava.vivanti.eu
										</a>
										,
									</li>
									<li>
										"Contractor / Client” – a legal entity, the client of the
										Vivanti SA,
									</li>
									<li>
										“Trainer” – a third party, who provides and/or uploads
										content to the Platform and may be engaged by us to manually
										evaluate the results of the simulation,
									</li>
									<li>
										"User”, “you”, “your” means the person using the Avatar AI
										Simulation Platform - a full-time or freelance employees of
										or contractors to the subscribing entity.
									</li>
								</ul>
							</RootDesc>

							<H2>1.&nbsp;Acceptance of&nbsp;Terms</H2>

							<RootDesc>
								<span>
									1.1 By accessing and using the Avatar AI Simulation Platform
									("Platform"), you agree to be legally bound by these Terms -.
									If you do not agree with any part of these Terms, you must not
									use the Platform.
								</span>

								<span>
									1.2 Users must be at least 18 years old or of legal age in
									their jurisdiction to use the Platform.
								</span>

								<span>
									1.3 These Terms may be updated from time to time. Continued
									use of the Platform after such changes will constitute your
									consent to such changes.
								</span>
							</RootDesc>

							<H2>2.&nbsp;Description of&nbsp;Service</H2>

							<RootDesc>
								<span>
									2.1 The Platform provides an artificial intelligence-powered
									simulation environment designed for pharmaceutical medical
									representatives to train in effective engagement with
									healthcare professionals (HCPs).
								</span>

								<span>
									2.2 The Platform offers various scenarios, feedback
									mechanisms, and tracking tools to enhance learning and
									performance.
								</span>

								<span>
									2.3 The simulation is intended solely for training purposes
									and not for actual sales or engagements with HCPs.
								</span>
							</RootDesc>

							<H2>3.&nbsp;Access and&nbsp;Usage</H2>

							<RootDesc>
								<span>
									3.1 The Platform is intended for use by authorised users who
									are full-time or freelance employees of or contractors to the
									subscribing entity.
								</span>

								<span>
									3.2 Users are granted a non-exclusive, non-transferable,
									revocable license to access and use the Platform strictly in
									accordance with these Terms.
								</span>

								<span>
									3.3 Users must keep their login credentials confidential and
									report any unauthorized access immediately.
								</span>

								<span>
									3.4 If the user's registration is created by the
									Contractor/Client, then the Contractor/Client is responsible
									for revoking the registration in case of dismissal of an
									employee or restriction of his access.
								</span>
							</RootDesc>

							<H2>4.&nbsp;User Obligations</H2>

							<RootDesc>
								<span>
									4.1 Users agree to provide accurate and complete information
									when registering on the Platform and to keep such information
									up to date.
								</span>

								<span>
									4.2 Users shall use all possible efforts to prevent
									unauthorised access to or use of Platform.
								</span>

								<span>
									4.3 Users agree to use the Platform only for lawful purposes
									and in a manner that does not infringe the rights of or
									restrict or inhibit the use and enjoyment of the Platform by
									any third party.
								</span>

								<span>
									4.4 Users shall not engage in any form of harassment or
									discrimination against others while using the Platform.
								</span>

								<span>
									4.5 The user is responsible for informing both the Platform
									and the Contractor about the non-disclosure agreement (NDA).
									If a confidentiality breach is traced back to their account,
									the user must disclose this breach.
								</span>

								<span>
									4.6 Users shall immediately inform us of any illegal or
									unauthorised activity or a security breach (including any
									loss, theft, or unauthorised disclosure or use of a username,
									password or account).
								</span>

								<span>
									4.7 Users shall not reproduce, copy, duplicate, sell, resell,
									rent or trade any of the material on our Platform (or any part
									thereof) for any purpose except in the case provided for in
									Section 5.
								</span>
							</RootDesc>

							<H2>5.&nbsp;Intellectual Property</H2>

							<RootDesc>
								<span>
									5.1 All intellectual property rights in the Platform and in
									the material published on it are owned by or licensed to us,
									including but not limited to all software, know-how, trade
									secrets, copyrights, design elements, scenarios, images and
									virtual scenes.
								</span>

								<span>
									5.2 The look and feel of the Platform (including all page
									headers, custom graphics, icons and scripts) form the
									trademark, service marks and trade dress, belonging to Vivanti
									SA and may not be used, copied and/or imitated in whole or in
									part, without the express prior written approval of us.
								</span>

								<span>
									5.3 Users may not reproduce, distribute, modify, create
									derivative works of, publicly display, publicly perform,
									republish, download, store, or transmit any of the material on
									our Platform except as generally and ordinarily permitted
									through the Platform according to these Terms.
								</span>

								<span>
									5.4 Users owns the simulation results created in connection
									with the Platform. Users grant us a license to use any content
									they create within the Platform for improvement and marketing
									purposes, provided that it does not identify individual users.
								</span>

								<span>
									5.5 The Contractor / Client and the trainer have the right to
									upload any materials necessary for creating simulations to the
									Platform, including but not limited to the training materials
									and content. Providing such materials does not entail the
									transfer of exclusive rights to them. The Platform uses such
									materials only for the purpose of ensuring the operation of
									the Platform.
								</span>

								<span>
									5.6 The parties, specified in clause 5.5 of the Terms, are
									responsible for ensuring that they have exclusive rights or
									other necessary permissions for the materials provided or
									uploaded to the Platform.
								</span>
							</RootDesc>

							<H2>6&nbsp;Data Protection</H2>

							<RootDesc>
								<span>
									6.1 The Platform complies with applicable data protection
									laws. We are committed to protecting the privacy and security
									of the data collected from users.
								</span>

								<span>
									6.2 Your privacy and personal information are important to us.
									Personal data provided by users or collected through the
									Platform will be used only in accordance with our Privacy
									Policy, which explains what personal information we collect
									from you, how and why we collect, store, use and share such
									information, your rights in relation to your personal
									information and how to contact us and supervisory authorities
									if you have a query or complaint about the use of your
									personal information.
								</span>

								<span>
									6.3 Our Privacy Policy is available at{' '}
									<Link to={'/privacy'}>https://ava.vivanti.eu/privacy</Link>
								</span>

								<span>
									6.4 User data may be used in AI training processes; users have
									rights regarding their data, including access, rectification,
									and deletion requests.
								</span>

								<span>
									6.5 We take all necessary measures and precautions to ensure
									that contractors have differentiated access to information.
								</span>

								<span>
									6.6 The data generated by users and the outcomes of their
									interactions on the Platform will be accessible to the
									contractor who registered them, in addition to being available
									to the Platform provider.
								</span>
							</RootDesc>

							<H2>7.&nbsp;Limitation of&nbsp;Liability</H2>

							<RootDesc>
								<span>
									7.1 The Platform is provided "as is" and "as available"
									without any warranties, express or implied, including but not
									limited to the implied warranties of merchantability, fitness
									for a particular purpose, or non-infringement.
								</span>

								<span>
									7.2 We shall not be liable for any direct, indirect,
									incidental, special, consequential, or punitive damages
									resulting from the use or inability to use the Platform.
								</span>

								<span>
									7.3 We are not responsible for any third-party links or
									content that may be accessed through the Platform.
								</span>

								<span>
									7.4 The simulator permits users to input any type of data,
									including personal information, outside of what is required –
									should they wish. Users should be aware that all information
									provided is stored in the Platform's database. The Platform’s
									provider limits its liability for any user-initiated data
									disclosure. Users are responsible for any personal data they
									choose to disclose.
								</span>
							</RootDesc>

							<H2>8.&nbsp;Indemnification</H2>

							<RootDesc>
								<span>
									8.1 Users agree to indemnify, defend, and hold harmless the
									Platform, its officers, directors, employees, agents,
									licensors, suppliers, and any third-party information
									providers from and against all losses, expenses, damages, and
									costs, including reasonable attorneys' fees resulting from any
									violation of these Terms.
								</span>
							</RootDesc>

							<H2>9.&nbsp;Termination</H2>

							<RootDesc>
								<span>
									9.1 We may terminate or suspend access to our Platform
									immediately, without prior notice or liability for any reason
									whatsoever, including without limitation if you breach these
									Terms.
								</span>

								<span>
									9.2 Upon termination of your access to the Platform, we will
									retain user-generated content as required for compliance
									reasons but will delete personal data as per our Privacy
									Policy.
								</span>
							</RootDesc>

							<H2>10.&nbsp;Governing Law</H2>

							<RootDesc>
								<span>
									10.1 These Terms shall be governed by and construed in
									accordance with the laws of the jurisdiction in which the
									Platform provider is based without regard to its conflict of
									law provisions.
								</span>

								<span>
									10.2 If our Platform operates in multiple jurisdictions,
									disputes will be handled according to applicable laws in those
									jurisdictions.
								</span>
							</RootDesc>

							<H2>11.&nbsp;Dispute Resolution</H2>

							<RootDesc>
								<span>
									11.1 We will try to resolve any disputes with you quickly and
									efficiently. If you are unhappy with our service to you or any
									other matters, please contact us as soon as possible using the
									contact details set out in Section 13.
								</span>

								<span>
									11.2 Any disputes arising out of or related to these Terms,
									which cannot be resolved through discussions with us, shall be
									resolved through binding arbitration rather than in court
									unless otherwise required by law.
								</span>
							</RootDesc>

							<H2>12.&nbsp;Miscellaneous</H2>

							<RootDesc>
								<span>
									12.1 Using the Platform doe not create a partnership, joint
									venture, agency, fiduciary or employment relationship between
									us and users.
								</span>
							</RootDesc>

							<H2>13.&nbsp;Contact Information</H2>

							<RootDesc>
								<span>
									13.1 If you have any questions about these Terms or need
									further assistance, please contact us at{' '}
									<a href='mailto:info@vivanti.eu' target='_blank'>
										info@vivanti.eu
									</a>
									.
								</span>
							</RootDesc>
						</div>
					</BorderArticle>
				</div>
			</div>

			<Footer />
		</>
	)
}
