import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { ProfileStatisticLayout } from '@/components/layouts/ProfileStatisticLayout'
import { Header } from '@/components/layouts/Header'
import { SideBar } from '@/components/layouts/SideBar'
import { UserAnalytics } from '@/screens/user/UserAnalytics'
import { SessionViewLayout } from '@/components/layouts/SessionLayout/SessionViewLayout'
import { Footer } from '@/components/layouts/Footer'

import styles from './styles.module.scss'

export const UserPanel = () => {
	const { id } = useSelector(state => state.candidate)

	const userBarList = useMemo(
		() => [
			{
				id: 0,
				iconId: 'profile',
				name: 'My Profile',
				link: `profile/${id}`,
			},
			{
				id: 1,
				iconId: 'analytics',
				name: 'Session History',
				link: 'analytics',
			},
		],
		[id]
	)

	return (
		<div className={styles.userPanel}>
			<Header />

			<div className='container-big'>
				<div className={styles.main}>
					<SideBar list={userBarList} />

					<Routes>
						<Route path='profile/:id' element={<ProfileStatisticLayout />} />
						<Route path='analytics/*' element={<UserAnalytics />} />
						<Route path='analytics/view/:id' element={<SessionViewLayout />} />
					</Routes>
				</div>
			</div>

			<Footer />
		</div>
	)
}
