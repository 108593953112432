import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StreamingAvatar, {
	AvatarQuality,
	StreamingEvents,
} from '@heygen/streaming-avatar'
import {
	clearAvatarState,
	createSession,
	setChatTime,
	setIsSessionActive,
	setServerStatus,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '@/components/ui/general/Spinner'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { AvatarErrorPopup } from '@/popups/simulationPopups/AvatarErrorPopup'
import styles from './styles.module.scss'

const AvatarComponent = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const level = location.state?.level
	const { openPopup, closePopup } = usePopup()
	const { isMobile } = useSelector(state => state.settings)
	const {
		avatarToken,
		avatarId,
		isSessionActive,
		botMessage,
		chatTime,
		score,
		messages,
		volume,
	} = useSelector(state => state.avatar)
	const videoRef = useRef(null)
	const [avatar, setAvatar] = useState(null)
	const [sessionData, setSessionData] = useState(null)
	const [avatarLoading, setAvatarLoading] = useState(false)

	const initializeAvatarSession = async () => {
		if (avatarToken) {
			setAvatarLoading(true)

			try {
				const newAvatar = new StreamingAvatar({ token: avatarToken })
				const data = await newAvatar.createStartAvatar({
					quality: AvatarQuality.High,
					avatarName: avatarId,
					voice: {
						rate: 1.5,
						emotion: 'serious',
					},
				})

				setSessionData(data)
				setAvatar(newAvatar)
				dispatch(setIsSessionActive(true))
				newAvatar.on(StreamingEvents.STREAM_READY, handleStreamReady)
				newAvatar.on(
					StreamingEvents.STREAM_DISCONNECTED,
					handleStreamDisconnected
				)
			} catch {
				openPopup(
					<AvatarErrorPopup
						onClickTryAgain={handleTryAgain}
						onClickExit={handleQuit}
					/>,
					{ closeButton: false }
				)
			}

			setAvatarLoading(false)
		}
	}

	const handleStreamReady = event => {
		if (event.detail && videoRef.current) {
			videoRef.current.srcObject = event.detail
			videoRef.current.onloadedmetadata = () => {
				videoRef.current.play().catch(console.error)
			}
		} else {
			openPopup(
				<AvatarErrorPopup
					onClickTryAgain={handleTryAgain}
					onClickExit={handleQuit}
				/>,
				{ closeButton: false }
			)
		}
	}

	const handleStreamDisconnected = () => {
		if (videoRef.current) {
			videoRef.current.srcObject = null
		}
		dispatch(setIsSessionActive(false))
	}

	const terminateAvatarSession = async () => {
		if (!avatar || !sessionData) return

		await avatar.stopAvatar()

		if (videoRef.current) {
			videoRef.current.srcObject = null
		}

		setAvatar(null)
		setSessionData(null)
		dispatch(setIsSessionActive(false))
	}

	const handleSpeak = async msg => {
		await avatar.speak({ text: msg, task_type: 'repeat' })
	}

	const handleClickQuitBtn = async () => {
		if (messages.length > 0 && chatTime > 0) {
			await terminateAvatarSession()
			openPopup(<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />, {
				closeButton: false,
			})
		} else {
			await terminateAvatarSession()
			navigate(-1)
		}
	}

	const handleTryAgain = async () => {
		closePopup()

		await dispatch(
			createSession({
				level: params.level,
				scenario_id: params.scenario_id,
			})
		)
	}

	const handleQuit = async () => {
		closePopup()
		await terminateAvatarSession()
		await dispatch(clearAvatarState())
		navigate('/simulations')
	}

	const handleCloseLevelPopup = async () => {
		closePopup()
		initializeAvatarSession()
	}

	useEffect(() => {
		if (chatTime) {
			let remainingTime = chatTime

			const timer = setInterval(() => {
				if (remainingTime <= 1000 || score === 0) {
					dispatch(clearAvatarState())
					clearInterval(timer)

					openPopup(
						<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />,
						{ closeButton: false }
					)
				} else {
					remainingTime -= 1000

					dispatch(setChatTime(remainingTime))
				}
			}, 1000)

			return () => clearInterval(timer)
		}
	}, [chatTime, score])

	useEffect(() => {
		if (avatar && botMessage !== '') {
			handleSpeak(botMessage)
		}
	}, [botMessage])

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.volume = volume
		}
	}, [volume])

	useEffect(() => {
		openPopup(<LevelPopup close={handleCloseLevelPopup} item={level} />, {
			closeButton: false,
		})

		return () => {
			if (avatarToken === '') {
				closePopup()
				dispatch(clearAvatarState())
				navigate('/simulations')
			}
		}
	}, [])

	useEffect(() => {
		if (avatar && sessionData && avatarToken !== '') {
			initializeAvatarSession()
		}
	}, [avatarToken])

	return (
		<>
			<div className={styles.avatarWrapper}>
				<div className={styles.avatarPlayer}>
					{!isSessionActive || avatarLoading ? (
						<Spinner width={80} height={80} />
					) : (
						<video id='avatarVideo' ref={videoRef} autoPlay playsInline />
					)}
				</div>
			</div>
			<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
				<i>
					<RootIcon width={32} height={32} id={'quit'} />
				</i>
				{!isMobile && (
					<RootDesc>
						<span>End the Call</span>
					</RootDesc>
				)}
			</div>
		</>
	)
}

export default AvatarComponent
