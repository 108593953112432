import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {
	getAllSessions,
	clearSessions,
} from '@/redux/slices/sessionSlices/sessionsSlice'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { Spinner } from '@/components/ui/general/Spinner'

import styles from './Activity.module.scss'

const levelStyles = {
	easy: { background: 'var(--blueLight)', color: 'var(--blue)' },
	medium: { background: 'var(--yellow)', color: 'var(--yellowDark)' },
	hard: { background: 'var(--red)', color: 'var(--redDark)' },
}

export const Activity = () => {
	const dispatch = useDispatch()

	const { sessions, serverStatus, error } = useSelector(state => state.sessions)

	const [opacityBottomBtn, setOpacityBottomBtn] = useState(1)
	const [opacityTopBtn, setOpacityTopBtn] = useState(0)

	const listRef = useRef(null)

	useEffect(() => {
		dispatch(getAllSessions())
	}, [dispatch])

	useEffect(() => {
		const handleScroll = () => {
			if (listRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = listRef.current

				const isAtBottom = scrollTop + clientHeight >= scrollHeight * 0.95
				const isAtTop = scrollTop <= scrollHeight * 0.05

				setOpacityBottomBtn(isAtBottom ? 0 : 1)
				setOpacityTopBtn(isAtTop ? scrollTop / (scrollHeight * 0.05) : 1)
			}
		}

		const listElement = listRef.current

		if (listElement) {
			listElement.addEventListener('scroll', handleScroll)
		}

		return () => {
			if (listElement) {
				listElement.removeEventListener('scroll', handleScroll)
			}

			dispatch(clearSessions())
		}
	}, [])

	const filteredSessions =
		sessions &&
		sessions.filter(session => {
			const createdAt = moment(session.created_at)

			return createdAt.isAfter(moment().subtract(24, 'hours'))
		})

	const scroll = direction => {
		if (listRef.current) {
			const scrollAmount = listRef.current.clientHeight

			listRef.current.scrollBy({
				top: direction * scrollAmount,
				behavior: 'smooth',
			})
		}
	}

	return (
		<div className={styles.activity}>
			{serverStatus === 'loading' && <Spinner width={50} height={50} />}

			<div className={styles.activityHead}>
				<H3>Last Activity</H3>
			</div>

			<div style={{ position: 'relative' }}>
				{listRef.current?.scrollHeight !== listRef.current?.clientHeight && (
					<div
						className={styles.scrollTopBtn}
						style={{ opacity: opacityTopBtn }}
						onClick={() => scroll(-1)}
					>
						<i></i>
					</div>
				)}

				<ul ref={listRef} className={styles.userList}>
					{serverStatus === 'success' &&
						filteredSessions &&
						filteredSessions.length === 0 && (
							<li className={styles.error}>
								<SmallDesc>
									<span className='text-[var(--grey)]'>
										No active sessions <br /> in the last 24 hours
									</span>
								</SmallDesc>
							</li>
						)}

					{serverStatus === 'error' && (
						<li className={styles.error}>
							<SmallDesc>
								<span className='text-[var(--red)]'>{error}</span>
							</SmallDesc>
						</li>
					)}

					{filteredSessions &&
						filteredSessions.length > 0 &&
						filteredSessions.map(session => (
							<li key={session?.id}>
								<div className={styles.itemHead}>
									<img src={session?.user_cover} alt='avatar' />

									<RootDesc>
										<span>
											{session?.user_country_code &&
												getUnicodeFlagIcon(session?.user_country_code)}
											&nbsp;&nbsp;
											{session?.user}
										</span>
									</RootDesc>
								</div>

								<div className={styles.itemFooter}>
									<div className={styles.itemFooterContent}>
										<SmallDesc>
											<span className='text-[var(--grey)]'>
												{moment(session?.created_at).fromNow()}
											</span>
										</SmallDesc>

										<RootDesc>
											<span>{session?.scenario}</span>
										</RootDesc>
									</div>

									<div
										style={levelStyles[session?.level]}
										className={styles.itemLevel}
									>
										{session?.level}
									</div>
								</div>
							</li>
						))}
				</ul>

				{listRef.current?.scrollHeight !== listRef.current?.clientHeight && (
					<div
						className={styles.scrollDownBtn}
						style={{ opacity: opacityBottomBtn }}
						onClick={() => scroll(1)}
					>
						<i></i>
					</div>
				)}
			</div>
		</div>
	)
}
