import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AvatarService from '@/services/AvatarService'
import { v4 as uuidv4 } from 'uuid'

export const createSession = createAsyncThunk(
	'create/session',
	async ({ level, scenario_id }) => {
		try {
			const response = await AvatarService.createSession({ level, scenario_id })

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const sendMessage = createAsyncThunk(
	'send/message',
	async ({ id, msg }) => {
		try {
			const response = await AvatarService.sendMessage({ id, msg })

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getFeedback = createAsyncThunk('session/feedback', async id => {
	try {
		const response = await AvatarService.getFeedback(id)

		return response.data
	} catch (err) {
		throw err
	}
})

const initialState = {
	avatarId: 'afffcc32c461440a94eff11c61eb0eb0',
	avatarToken: '',
	userMessage: '',
	botMessage: '',
	messages: [],
	score: null,
	session_id: null,
	isRecording: false,
	isSessionActive: false,
	chatTime: 0,
	volume: 0.2,
	error: '',
	serverStatus: '',
	feedback: '',
}

const avatarSlice = createSlice({
	name: 'avatar',
	initialState,
	reducers: {
		setIsRecording(state, action) {
			state.isRecording = action.payload
		},
		setIsSessionActive(state, action) {
			state.isSessionActive = action.payload
		},
		setChatTime(state, action) {
			state.chatTime = action.payload
		},
		setUserMessage(state, action) {
			state.userMessage = action.payload
		},
		setBotMessage(state, action) {
			state.botMessage = action.payload
		},
		setMessages(state, action) {
			state.messages.push(action.payload)
		},
		setVolume(state, action) {
			state.volume = action.payload
		},
		setAvatarToken(state, action) {
			state.avatarToken = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearAvatarState(state, action) {
			state.userMessage = ''
			state.botMessage = ''
			state.messages = []
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(createSession.fulfilled, (state, action) => {
				state.session_id = action.payload.id
				state.avatarToken = action.payload.heygen?.data?.token || ''
				state.error = action.payload.heygen?.error
					? action.payload.heygen.error
					: ''
				state.serverStatus = 'success'
			})
			.addCase(createSession.rejected, state => {
				state.error = 'Don`t create session!'
				state.serverStatus = 'error'
			})

			.addCase(getFeedback.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getFeedback.fulfilled, (state, action) => {
				state.feedback = action.payload.feedback
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getFeedback.rejected, state => {
				state.error = 'Don`t get feedback!'
				state.serverStatus = 'error'
			})

			.addCase(sendMessage.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				state.botMessage = action.payload.ai
				state.score = action.payload.score
				state.error = ''
				state.serverStatus = 'success'

				if (action.payload) {
					const bot = {
						messageId: uuidv4(),
						text: state.botMessage,
						sender: 'bot',
					}

					state.messages.push(bot)
				}
			})
			.addCase(sendMessage.rejected, state => {
				state.error = 'Don`t send message!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setChatTime,
	setBotMessage,
	setUserMessage,
	setMessages,
	setIsSessionActive,
	setVolume,
	setAvatarToken,
	setError,
	setServerStatus,
	serverStatus,
	clearAvatarState,
	setIsRecording,
} = avatarSlice.actions
export default avatarSlice.reducer
