import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
	signIn,
	setError,
	setEmail,
	setPassword,
	toggleConsent,
} from '@/redux/slices/candidateSlice'

import { isValidEmail } from '@/helpers/helpers'
import { RootPopup } from '@/components/layouts/RootPopup'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { HelpPopup } from '@/popups/requestPopups/HelpPopup'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { CallPopup } from '@/popups/requestPopups/CallPopup'
import { PresentationPopup } from '@/popups/requestPopups/PresentationPopup'
import { Spinner } from '@/components//ui/general/Spinner'
import { FormError } from '@/components/ui/general/FormError'
import ReactPlayer from 'react-player'
import { Logo } from '@/components/ui/general/Logo'

import fallbackImage from '@/assets/images/general/video-fallback.png'
import styles from './styles.module.scss'

//import video from '@/assets/video.mp4'

export const Login = () => {
	const video = '/video/video.mp4'

	const { isMobile } = useSelector(state => state.settings)
	const { email, password, consent, error, serverStatus } = useSelector(
		state => state.candidate
	)
	const { openPopup } = usePopup()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [isPlaying, setIsPlaying] = useState(true)
	const [videoDuration, setVideoDuration] = useState(0)
	const [videoError, setVideoError] = useState(false)

	const handleVideoEnded = () => {
		setIsPlaying(false)
	}

	const handleVideoError = () => {
		setVideoError(true)
	}

	const handleProgress = progress => {
		if (progress.playedSeconds >= videoDuration - 1 && isPlaying) {
			setIsPlaying(false)
		}
	}

	const handleDuration = duration => {
		setVideoDuration(duration)
	}

	const handleOpenPopup = popup => {
		openPopup(popup)
	}

	const handleSubmit = async e => {
		e.preventDefault()
		dispatch(setError(''))

		if (!isValidEmail(email) || !consent || !password) {
			return dispatch(setError('Login error! Check your login and password!'))
		}

		const resultAction = await dispatch(signIn({ email, password }))

		if (signIn.fulfilled.match(resultAction)) {
			const userRole = resultAction.payload.type

			if (userRole === 'Admin' || userRole === 'Manager') {
				navigate('/admin-panel/dashboard')
			} else {
				navigate('/simulations/*')
			}
		} else {
			dispatch(setError('Login error! Check your login and password!'))
		}
	}

	const buttonList = [
		{
			id: 0,
			name: 'Book a Сall',
			iconId: 'call',
			popup: <CallPopup />,
		},
		{
			id: 1,
			name: 'Request a Presentation',
			iconId: 'request',
			popup: <PresentationPopup />,
		},
		{
			id: 2,
			name: 'Help',
			iconId: 'question',
			popup: <HelpPopup />,
		},
	]

	const footerLinkList = [
		{
			id: 0,
			name: 'Privacy Policy',
			link: '/privacy',
		},
		{
			id: 1,
			name: 'Terms of Service',
			link: '/terms',
		},
	]

	useEffect(() => {
		dispatch(setError(''))
	}, [email, password, consent])

	return (
		<div className={styles.loginWrapper}>
			<div className='container-big'>
				<div className={styles.login}>
					<Logo />

					<div className={styles.loginContent}>
						<h1>Welcome to the Simulation</h1>

						<h2>
							Become a Master of HCP Engagement — <br />
							Where Expertise Meets Excellence
						</h2>

						<RootPopup width={440}>
							<div className={styles.content}>
								<RootDesc>
									<span className='inline-block text-center w-[100%]'>
										Enter the login and password provided
										<br />
										to you to begin communicating with the AI assistant
									</span>
								</RootDesc>

								<form onSubmit={handleSubmit} className={styles.form}>
									<div className={styles.inputs}>
										<div className={styles.input}>
											<RootInput
												width={220}
												label={'Login'}
												type={'text'}
												value={email}
												require={true}
												onChange={e => dispatch(setEmail(e.target.value))}
												disabled={serverStatus === 'loading'}
											/>
										</div>

										<div className={styles.input}>
											<RootInput
												width={220}
												label={'Password'}
												type={'password'}
												value={password}
												require={true}
												onChange={e => dispatch(setPassword(e.target.value))}
												disabled={serverStatus === 'loading'}
											/>
										</div>
									</div>

									<RootCheckbox
										checked={consent}
										onChange={() => dispatch(toggleConsent())}
										label={
											<>
												By clicking you agree to our{' '}
												<Link to={'/privacy'}>Privacy Statement</Link>
											</>
										}
										disabled={serverStatus === 'loading'}
									/>

									<RootButton
										type={'submit'}
										txt={<span>Start communicate with Ava</span>}
										disabled={
											!email ||
											!password ||
											!consent ||
											serverStatus === 'loading'
										}
									/>

									{error !== '' && <FormError error={error} bottom={105} />}

									{serverStatus === 'loading' && (
										<Spinner width={50} height={50} />
									)}
								</form>
							</div>
						</RootPopup>

						<ul className={styles.loginButtons}>
							{buttonList.map(btn => (
								<li key={btn.id}>
									<ControlButton
										color='var(--black)'
										txt={btn.name}
										iconId={btn.iconId}
										onClick={() => handleOpenPopup(btn.popup)}
									/>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>

			{!isMobile && (
				<div className={styles.loginVideoWrapper}>
					<div className='container-big'>
						<div className={styles.loginVideo}>
							{video && !videoError ? (
								<ReactPlayer
									playing={isPlaying}
									url={video}
									volume={0}
									muted={true}
									width={'1840rem'}
									height={'900rem'}
									onEnded={handleVideoEnded}
									onProgress={handleProgress}
									onDuration={handleDuration}
									onError={handleVideoError}
								/>
							) : (
								<div>
									<img src={fallbackImage} alt='doctor' />
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			<div className={styles.loginFooter}>
				<div className='container-big'>
					<div className={styles.footer}>
						<RootDesc>
							<span className='text-[var(--white)] phone:text-[var(--grey)]'>
								© 2024 Vivanti SA. All rights reserved.
							</span>
						</RootDesc>

						<ul>
							{footerLinkList.map(item => (
								<li key={item.id}>
									<Link to={item.link}>{item.name}</Link>
								</li>
							))}
						</ul>

						<RootDesc>
							<span className='text-[var(--white)] phone:text-[var(--grey)] phone:block phone:text-center'>
								For inquiries, contact us at:{' '}
								<a
									href='mailto:info@vivanti.eu'
									target='_blank'
									rel='noopener noreferrer'
								>
									info@vivanti.eu
								</a>
							</span>
						</RootDesc>
					</div>
				</div>
			</div>
		</div>
	)
}
