import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProductsService from '@/services/ProductsService'

export const getCountries = createAsyncThunk('product/countries', async () => {
	try {
		const response = await ProductsService.getCountries()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

export const getProductLine = createAsyncThunk(
	'product/product_line',
	async () => {
		try {
			const response = await ProductsService.getProductLine()

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	countries: [],
	productLine: [],
	error: '',
	serverStatus: '',
}

const productOptionsSlice = createSlice({
	name: 'product-options',
	initialState,
	reducers: {
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearProductOptions() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getCountries.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getCountries.fulfilled, (state, action) => {
				state.countries = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getCountries.rejected, (state, action) => {
				state.error = 'Don`t get countries!'
				state.serverStatus = 'error'
			})

			.addCase(getProductLine.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getProductLine.fulfilled, (state, action) => {
				state.productLine = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getProductLine.rejected, (state, action) => {
				state.error = 'Don`t get countries!'
				state.serverStatus = 'error'
			})
	},
})

export const { setError, setServerStatus, clearProductOptions } =
	productOptionsSlice.actions
export default productOptionsSlice.reducer
