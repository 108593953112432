import { useLocation, useNavigate } from 'react-router-dom'

import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { HelpPopup } from '@/popups/requestPopups/HelpPopup'
import { usePopup } from '../InfoPopup/PopupContext'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

export const SideBar = props => {
	const { list } = props

	const location = useLocation()
	const navigate = useNavigate()

	const { openPopup } = usePopup()
	const { isMobile } = useSelector(state => state.settings)
	const { role } = useSelector(state => state.candidate)

	const [isOpen, setIsOpen] = useState(false)
	const [activeNav, setActiveNav] = useState({})
	const dropdownRef = useRef(null)

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	const handleClickLink = item => {
		navigate(item.link)
		setActiveNav(item)
		setIsOpen(false)
	}

	const handleOpenPopup = () => {
		openPopup(<HelpPopup />)
	}

	useEffect(() => {
		list.map(item => {
			if (location.pathname.includes(item.link)) {
				setActiveNav(item)
			}

			if (location.pathname.includes('profile')) {
				setActiveNav(list[0])
			}
		})
	}, [location])

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={styles.sidebarWrapper}>
			<RootButton
				onClick={() => navigate('/simulations')}
				txt={
					role !== 'Representative'
						? 'Simulations and Training'
						: 'Back to Simulations'
				}
			/>

			{!isMobile ? (
				<>
					<BorderArticle>
						<ul className={styles.sidebar}>
							{list &&
								list.map(item => (
									<li
										className={
											location.pathname.includes(item.link)
												? styles.link + ' ' + styles.activeLink
												: styles.link
										}
										key={item.id}
										onClick={() => handleClickLink(item)}
									>
										<RootIcon id={item.iconId} />

										<RootDesc>
											<span>{item.name}</span>
										</RootDesc>
									</li>
								))}
						</ul>
					</BorderArticle>

					<ControlButton
						color='var(--black)'
						txt={'Help'}
						iconId={'question'}
						onClick={handleOpenPopup}
					/>
				</>
			) : (
				<div className={styles.dropdown} ref={dropdownRef}>
					<BorderArticle>
						<div className='w-[100%]'>
							<div className={styles.dropdownHeader} onClick={toggleDropdown}>
								<div className={styles.link}>
									<RootIcon width={32} height={32} id={activeNav.iconId} />

									<RootDesc>
										<span>{activeNav.name}</span>
									</RootDesc>
								</div>

								<i
									style={
										isOpen
											? { transform: 'rotate(180deg)' }
											: { transform: 'rotate(0deg)' }
									}
								></i>
							</div>

							{isOpen && (
								<div className={styles.dropdownList}>
									<ul>
										{list &&
											list
												.filter(item => item.id !== activeNav.id)
												.map(item => (
													<li
														key={item.id}
														onClick={() => handleClickLink(item)}
														className={
															location.pathname.includes(item.link)
																? styles.link + ' ' + styles.activeLink
																: styles.link
														}
													>
														<RootIcon width={32} height={32} id={item.iconId} />

														<RootDesc>
															<span>{item.name}</span>
														</RootDesc>
													</li>
												))}
									</ul>
								</div>
							)}
						</div>
					</BorderArticle>
				</div>
			)}
		</div>
	)
}
