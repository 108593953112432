import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import styles from './styles.module.scss'

export const RootButton = props => {
	const { txt, type, onClick, disabled } = props

	return type === 'submit' ? (
		<button
			className={styles.rootButton}
			type={type || 'button'}
			disabled={disabled}
		>
			<RootDesc>{txt}</RootDesc>
		</button>
	) : (
		<button
			className={styles.rootButton}
			type={type || 'button'}
			onClick={onClick}
			disabled={disabled}
		>
			<RootDesc>{txt}</RootDesc>
		</button>
	)
}
