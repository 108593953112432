import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const helpRequest = createAsyncThunk(
	'request/help',
	async ({ name, email, description }) => {
		try {
			await axios.post(`/guest/requests/assistance`, {
				name,
				email,
				description,
			})
		} catch (e) {
			console.log(err)
		}
	}
)

const initialState = {
	name: '',
	email: '',
	description: '',
	agree: false,
	error: '',
	serverStatus: '',
}

const helpSlice = createSlice({
	name: 'help-form',
	initialState,
	reducers: {
		setName(state, action) {
			state.name = action.payload
		},
		setEmail(state, action) {
			state.email = action.payload
		},
		setAgree(state, action) {
			state.agree = action.payload
		},
		setDescription(state, action) {
			state.description = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearHelpForm() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(helpRequest.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(helpRequest.fulfilled, (state, action) => {
				state.name = ''
				state.email = ''
				state.description = ''
				state.agree = false
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(helpRequest.rejected, (state, action) => {
				state.error = 'Don`t send!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setName,
	setEmail,
	setDescription,
	setError,
	setAgree,
	setServerStatus,
	clearHelpForm,
} = helpSlice.actions
export default helpSlice.reducer
