import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'

export const RootSelect = props => {
	const { options, onSelect, placeholder } = props
	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState(options[0])
	const selectRef = useRef(null)

	const toggleSelect = () => {
		setIsOpen(!isOpen)
	}

	const handleOptionClick = option => {
		setSelectedOption(option)
		onSelect(option)
		setIsOpen(false)
	}

	const handleClickOutside = event => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<BorderArticle>
			<div ref={selectRef} className={styles.select}>
				<div className={styles.selectHeader} onClick={toggleSelect}>
					<RootDesc>
						<span>{placeholder}</span>
					</RootDesc>

					<i
						style={
							isOpen
								? { transform: 'rotate(180deg)' }
								: { transform: 'rotate(0deg)' }
						}
					></i>
				</div>

				{isOpen && (
					<div className={styles.selectList}>
						<BorderArticle>
							<ul>
								{options.map(option => (
									<li key={option.id} onClick={() => handleOptionClick(option)}>
										<SmallDesc>
											<span>{option.name}</span>
										</SmallDesc>
									</li>
								))}
							</ul>
						</BorderArticle>
					</div>
				)}
			</div>
		</BorderArticle>
	)
}

export default RootSelect
