import React from 'react'

import styles from './styles.module.scss'

const UserImage = ({ photoFile, editUser, user, photoImage, onClick }) => {
	const imageSrc = photoFile
		? URL.createObjectURL(photoFile)
		: editUser?.cover || user?.cover

	return (
		<div onClick={onClick} className={styles.userImage}>
			<img
				className={styles.userPreview}
				alt='preview'
				style={!imageSrc ? { background: 'var(--greyLight)' } : {}}
				src={imageSrc}
			/>

			{photoImage && (
				<img className={styles.photoImage} src={photoImage} alt='photo' />
			)}
		</div>
	)
}

export default UserImage
