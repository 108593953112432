import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearEditVideo,
	getEditVideo,
	setEditVideo,
	setIsVideoEdit,
} from '@/redux/slices/videoSlices/editVideoSlice'
import {
	clearScenarioOptions,
	getProducts,
} from '@/redux/slices/scenarioSlices/scenarioOptionsSlice'
import {
	clearProductOptions,
	getCountries,
	getProductLine,
} from '@/redux/slices/productSlices/productOptionsSlice'

import { isEqual } from '@/helpers/helpers'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { EditInput } from '@/components/ui/inputs/EditInput'
import { usePopup } from '../InfoPopup/PopupContext'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { DocumentPreview } from '@/components/ui/general/DocumentPreview'
import { PreviewCard } from '@/components/ui/general/PreviewCard'
import { Spinner } from '@/components/ui/general/Spinner'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'

import styles from './styles.module.scss'

export const VideoEditLayout = () => {
	const { openPopup } = usePopup()

	const id = useParams().id
	const dispatch = useDispatch()
	const location = useLocation()

	const { isMobile } = useSelector(state => state.settings)
	const { editVideo, initialEditVideo, serverStatus } = useSelector(
		state => state.editVideo
	)
	const { products } = useSelector(state => state.scenarioOptions)
	const { productLine, countries } = useSelector(state => state.productOptions)

	const [videoFile, setVideoFile] = useState(null)
	const [photoFile, setPhotoFile] = useState(null)

	const videoRef = useRef()

	const updateVideoField = (fieldName, value) => {
		dispatch(setEditVideo({ ...editVideo, [fieldName]: value }))
	}

	const handleClickRemovePhoto = () => {
		setPhotoFile(null)
		updateVideoField('cover', null)
	}

	const handleClickRemoveVideo = () => {
		setVideoFile(null)
		updateVideoField('video', null)
	}

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickUploadVideo = () => {
		openPopup(<UploadImagePopup setPhotoFile={setVideoFile} />)
	}

	useEffect(() => {
		if (editVideo && initialEditVideo) {
			dispatch(setIsVideoEdit(!isEqual(editVideo, initialEditVideo)))
		}
	}, [editVideo, initialEditVideo, dispatch])

	useEffect(() => {
		dispatch(getEditVideo({ id }))
		dispatch(getProducts())
		dispatch(getCountries())
		dispatch(getProductLine())

		return () => {
			dispatch(clearEditVideo())
			dispatch(clearScenarioOptions())
			dispatch(clearProductOptions())
		}
	}, [dispatch])

	useEffect(() => {
		if (videoRef.current && isMobile) {
			videoRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={videoRef} className={styles.videoWrapper}>
			<BorderArticle>
				<div className={styles.video}>
					<div className={styles.videoHead}>
						<BackButton />
					</div>

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.videoContent}>
							<div className={styles.videoEditContent}>
								<div className={styles.videoContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<EditInput
											width={!isMobile ? 350 : 210}
											placeholder={`Video name*`}
											label='video-name'
											type='text'
											value={editVideo?.name}
											onChange={e => updateVideoField('name', e.target.value)}
										/>

										<ControlButton
											color='var(--green)'
											txt={'Save'}
											onClick={() => console.log('save')}
										/>
									</div>

									<div className='flex items-start gap-[16rem] phone:flex-col'>
										<RootDesc>
											<b className='block w-[150rem]'>Video</b>
										</RootDesc>

										<div className='flex flex-col items-start gap-[16rem]'>
											{videoFile ||
												(editVideo?.video && (
													<DocumentPreview
														name={
															videoFile?.name ||
															editVideo?.video_metadata?.filename
														}
														onClickCross={() => handleClickRemoveVideo()}
													/>
												))}

											<ControlButton
												color='var(--violetLight)'
												txt={'Upload Video'}
												onClick={() => handleClickUploadImage()}
											/>
										</div>
									</div>

									<div className='flex items-start gap-[16rem] phone:flex-col'>
										<RootDesc>
											<b className='block w-[150rem]'>Cover</b>
										</RootDesc>

										<div className='flex flex-col items-start gap-[16rem]'>
											{photoFile ||
												(editVideo?.cover && (
													<DocumentPreview
														name={
															photoFile?.name ||
															editVideo?.cover_metadata?.filename
														}
														onClickCross={() => handleClickRemovePhoto()}
													/>
												))}

											<ControlButton
												color='var(--violetLight)'
												txt={'Upload Image'}
												onClick={() => handleClickUploadImage()}
											/>
										</div>
									</div>
								</div>

								<div className='flex flex-col gap-[24rem] items-start'>
									<RootDesc>
										<b>Preview</b>
									</RootDesc>

									<BorderArticle>
										<PreviewCard
											name={editVideo?.name}
											mask={editVideo?.mask}
											image={
												photoFile
													? URL.createObjectURL(photoFile)
													: editVideo?.cover || undefined
											}
										/>
									</BorderArticle>
								</div>
							</div>

							<ul className={styles.videoContentBody + ' ' + styles.videoBody}>
								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Related Products<sup>*</sup>
										</b>
									</RootDesc>

									{products && products.length > 0 && (
										<div className='w-[1110rem] phone:w-[100%]'>
											<EditSelect
												width={500}
												searchPlaceholder='Start typing Product Name'
												multiSelect={true}
												activeOption={
													editVideo?.products
														? editVideo?.products
																.split(',')
																.map(item => item.trim())
														: []
												}
												options={products}
												onSelect={selectedOption =>
													handleSelect(selectedOption)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Product Line<sup>*</sup>
										</b>
									</RootDesc>

									{productLine && productLine.length > 0 && (
										<div className='w-[240rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder='Search Product Line'
												activeOption={editVideo?.product_line}
												options={productLine}
												onSelect={selectedOption =>
													updateVideoField('product_line_id', selectedOption.id)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Country<sup>*</sup>
										</b>
									</RootDesc>

									{countries && countries.length > 0 && (
										<div className='w-[240rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder='Search Country'
												activeOption={editVideo?.country}
												options={countries}
												onSelect={selectedOption =>
													updateVideoField('country_id', selectedOption.id)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											Video Description
										</b>
									</RootDesc>

									<RootTextarea
										type={'text'}
										value={editVideo?.description}
										onChange={e =>
											updateVideoField('description', e.target.value)
										}
									/>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
