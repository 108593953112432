import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	setName,
	setEmail,
	clearHelpForm,
	setDescription,
	setError,
	setAgree,
	helpRequest,
} from '@/redux/slices/requestSlices/helpSlice'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { Spinner } from '@/components//ui/general/Spinner'
import { FormError } from '@/components/ui/general/FormError'

import styles from './styles.module.scss'

export const HelpPopup = () => {
	const { auth } = useSelector(state => state.candidate)
	const { name, email, description, agree, error, serverStatus } = useSelector(
		state => state.help
	)
	const dispatch = useDispatch()
	const location = useLocation()

	const handleSubmit = async e => {
		e.preventDefault()
		dispatch(setError(''))

		if (auth) {
			if (!description) {
				dispatch(setError('Please fill out all fields!'))
				return
			}

			const resultAction = await dispatch(helpRequest({ description }))

			if (helpRequest.fulfilled.match(resultAction)) {
				dispatch(clearHelpForm())
			} else {
				dispatch(setError('Error sending data!'))
			}
		} else {
			if (!description || !name || !email) {
				dispatch(setError('Please fill out all fields!'))
				return
			}

			const resultAction = await dispatch(
				helpRequest({ description, name, email })
			)

			if (helpRequest.fulfilled.match(resultAction)) {
				dispatch(clearHelpForm())
			} else {
				dispatch(setError('Error sending data!'))
			}
		}
	}

	useEffect(() => {
		dispatch(setError(''))
	}, [name, email, description, agree])

	useEffect(() => {
		return () => {
			dispatch(clearHelpForm())
		}
	}, [])

	return (
		<div className={styles.help}>
			<H3>Need Assistance?</H3>

			<RootDesc>
				<span>
					If you're facing any issues or need help with something, please let us
					know. Describe your problem in the box below, and our administrator
					will get back to you as soon as possible.
				</span>
			</RootDesc>

			<form onSubmit={handleSubmit} className={styles.helpForm}>
				{location.pathname.includes('login') && (
					<>
						<div className={styles.input}>
							<RootInput
								width={500}
								label={'Name'}
								type={'text'}
								value={name}
								required={true}
								onChange={e => dispatch(setName(e.target.value))}
								disabled={serverStatus === 'loading' && true}
							/>
						</div>

						<div className={styles.input}>
							<RootInput
								width={500}
								label={'Email'}
								type={'email'}
								value={email}
								required={true}
								onChange={e => dispatch(setEmail(e.target.value))}
								disabled={serverStatus === 'loading' && true}
							/>
						</div>
					</>
				)}

				<div className={styles.input}>
					<RootTextarea
						label={
							<>
								Problem <br /> Description
							</>
						}
						type={'text'}
						value={description}
						required={true}
						onChange={e => dispatch(setDescription(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				{location.pathname.includes('login') ? (
					<>
						<div className={styles.helpCheckbox}>
							<RootCheckbox
								fontSize={14}
								checked={agree}
								onChange={e => dispatch(setAgree(e.target.checked))}
								label={
									<>
										By clicking you agree to our{' '}
										<Link onClick={() => closePopup()} to={'/privacy'}>
											Privacy Statement
										</Link>
									</>
								}
							/>
						</div>

						<RootButton
							txt={'Submit'}
							type={'submit'}
							disabled={
								!description ||
								!email ||
								!name ||
								!agree ||
								serverStatus === 'loading'
							}
						/>
					</>
				) : (
					<RootButton
						txt={'Submit'}
						type={'submit'}
						disabled={!description || serverStatus === 'loading'}
					/>
				)}

				{error !== '' && <FormError error={error} bottom={105} />}

				{serverStatus === 'loading' && <Spinner width={50} height={50} />}
			</form>
		</div>
	)
}
