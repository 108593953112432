import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { LibraryLayout } from '@/components/layouts/LibraryLayout'
import doctorObjections from '@/assets/example-videos/doctor-objections.jpg'
import sideEffects from '@/assets/example-videos/side-effects.png'
import painxicam from '@/assets/example-videos/painxicam.png'
import rolexin from '@/assets/example-videos/rolexin.png'

export const Library = () => {
	const videos = useMemo(
		() => [
			{
				id: uuidv4(),
				title: 'Handling Doctor Objections',
				description:
					'This training video provides strategies for addressing common objections raised by doctors. Learn key techniques to respond confidently and effectively, ensuring productive conversations and stronger professional relationships.',
				category: 'Objections',
				time: '14 minutes',
				image: doctorObjections,
			},
			{
				id: uuidv4(),
				title: 'Rolexin: Marketing Strategy C1',
				description:
					'This training session provides an overview of the key topics for calls in Cycle 1, including the objectives and goals of these calls, as well as the essential messages to communicate.',
				category: 'Neurology',
				time: '9 minutes',
				image: rolexin,
			},
			{
				id: uuidv4(),
				title: 'Painxicam: Marketing Strategy C1',
				description:
					'This training session provides an overview of the key topics for calls in Cycle 1, including the objectives and goals of these calls, as well as the essential messages to communicate.',
				category: 'Neurology',
				time: '11 minutes',
				image: painxicam,
			},
			{
				id: uuidv4(),
				title: 'Rolexin: Side Effects and Drug Compatibility',
				description:
					'This training video explores the side effects associated with Rolexin and its compatibility with other medications. Participants will gain a comprehensive understanding of the potential adverse reactions, how to recognize them, and the importance of drug interactions.',
				category: 'Neurology',
				time: '7 minutes',
				image: sideEffects,
			},
		],
		[]
	)

	return (
		<div className='container-big'>
			<div className={styles.library}>
				<LibraryLayout videos={videos} />
			</div>
		</div>
	)
}
