import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ScenarioService from '@/services/ScenariosService'

export const initialCreateScenario = {
	name: '',
	mask: '',
	product_ids: [],
	purpose: '',
	objectives: '',
	focus: '',
	key_messages: '',
	structure: '',
	script: '',
	questions: '',
	error: '',
	serverStatus: '',
}

export const postCreateScenario = createAsyncThunk(
	'scenario/create',
	async ({ scenario }) => {
		try {
			await ScenarioService.createScenario({ scenario })
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isScenarioCreate: false,
	saveScenarioCreate: false,
	error: '',
	serverStatus: '',
	createScenario: initialCreateScenario,
}

const createScenarioSlice = createSlice({
	name: 'create-scenario',
	initialState,
	reducers: {
		setIsScenarioCreate(state, action) {
			state.isScenarioCreate = action.payload
		},
		setSaveScenarioCreate(state, action) {
			state.saveScenarioCreate = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setCreateScenario(state, action) {
			state.createScenario = action.payload
		},
		clearCreateScenario() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(postCreateScenario.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(postCreateScenario.fulfilled, (state, action) => {
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(postCreateScenario.rejected, (state, action) => {
				state.error = 'Don`t create scenario!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setIsScenarioCreate,
	setCreateScenario,
	setSaveScenarioCreate,
	setError,
	setServerStatus,
	clearCreateScenario,
} = createScenarioSlice.actions
export default createScenarioSlice.reducer
