import $api from '../http'

export default class UploadFilesService {
	static async uploadUsers(file, signal, onUploadProgress) {
		return $api.post('/api/v1/users/import', file, {
			signal,
			onUploadProgress,
		})
	}

	static async uploadAvatar(file, signal, onUploadProgress) {
		return $api.post('/api/v1/avatar/import', file, {
			signal,
			onUploadProgress,
		})
	}
}
