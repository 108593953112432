import React from 'react'
import styles from './styles.module.scss'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { DropZone } from '@/components/ui/general/DropZone'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'

export const UploadImagePopup = ({ setPhotoFile }) => {
	const { closePopup } = usePopup()

	const uploadFile = file => {
		if (file) {
			setPhotoFile(file)
			closePopup()
		} else {
			console.log('File is undefined!')
		}
	}

	return (
		<div className={styles.upload}>
			<H3>Upload an Image for Your Card Cover</H3>

			<div className={styles.uploadDesc}>
				<RootDesc>
					<span>
						Please select and upload an image to be used on your card.
					</span>
				</RootDesc>
			</div>

			<DropZone
				maxFiles={1}
				accept={{ 'image/*': ['.png', '.jpg'] }}
				onUpload={uploadFile}
				size={50000000}
				type={'jpg, png'}
			/>
		</div>
	)
}
