import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { Feedback } from './Feedback'
import { Statistic } from './Statistic'
import { UserViewLayout } from '../UserLayout/UserViewLayout'
import { UserEditLayout } from '../UserLayout/UserEditLayout'
import { ProfileLayout } from '../ProfileLayout'
import styles from './styles.module.scss'

const PATHS = {
	VIEW: '/view/',
	EDIT: '/edit/',
	PROFILE: '/profile',
}

export const ProfileStatisticLayout = () => {
	const location = useLocation()

	const { isMobile } = useSelector(state => state.settings)
	const { role } = useSelector(state => state.candidate)
	const { user } = useSelector(state => state.viewUser)
	const { editUser } = useSelector(state => state.editUser)
	const profileRef = useRef(null)

	useEffect(() => {
		if (profileRef.current && isMobile) {
			profileRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	const renderLayout = () => {
		if (location.pathname.includes(PATHS.VIEW)) {
			return <UserViewLayout />
		}

		if (location.pathname.includes(PATHS.EDIT)) {
			return <UserEditLayout />
		}

		return <ProfileLayout />
	}

	const shouldShowFeedback = () => {
		return (
			(role !== 'Admin' && location.pathname.includes(PATHS.PROFILE)) ||
			((role === 'Admin' || role === 'Manager') &&
				!location.pathname.includes(PATHS.PROFILE) &&
				user?.role !== 'Admin' &&
				editUser?.role !== 'Admin')
		)
	}

	return (
		<div className='container-small'>
			<div ref={profileRef} className={styles.profileWrapper}>
				<BorderArticle>{renderLayout()}</BorderArticle>

				{shouldShowFeedback() && (
					<BorderArticle>
						<Feedback />
					</BorderArticle>
				)}

				<BorderArticle>
					<Statistic />
				</BorderArticle>
			</div>
		</div>
	)
}
