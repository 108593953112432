import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	viewTabsId: 0,
}

const simulationsSlice = createSlice({
	name: 'simulations',
	initialState,
	reducers: {
		setViewTabsId(state, action) {
			state.viewTabsId = action.payload
		},
	},
})

export const { setViewTabsId } = simulationsSlice.actions
export default simulationsSlice.reducer
