import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersService from '@/services/UsersService'

export const getReps = createAsyncThunk('user/reps', async () => {
	try {
		const response = await UsersService.getReps()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

export const getManagers = createAsyncThunk('user/managers', async () => {
	try {
		const response = await UsersService.getManagers()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	reps: [],
	managers: [],
	error: '',
	serverStatus: '',
}

const userOptionsSlice = createSlice({
	name: 'user-options',
	initialState,
	reducers: {
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearUserOptions() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getReps.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getReps.fulfilled, (state, action) => {
				state.reps = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getReps.rejected, (state, action) => {
				state.error = 'Don`t get reps!'
				state.serverStatus = 'error'
			})

			.addCase(getManagers.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getManagers.fulfilled, (state, action) => {
				state.managers = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getManagers.rejected, (state, action) => {
				state.error = 'Don`t get countries!'
				state.serverStatus = 'error'
			})
	},
})

export const { setError, setServerStatus, clearUserOptions } =
	userOptionsSlice.actions
export default userOptionsSlice.reducer
