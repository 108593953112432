import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearUsers,
	getUsers,
	setPage,
	setSize,
	setSort,
	setTotalPages,
} from '@/redux/slices/userSlices/usersSlice'
import moment from 'moment'

import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { TableLayout } from '@/components/layouts/TableLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { UploadFileProgressPopup } from '@/popups/uploadPopups/UploadFileProgressPopup'
import { TableMobileLayout } from '@/components/layouts/TableMobileLayout'

export const Users = () => {
	const { openPopup } = usePopup()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { demo } = useSelector(state => state.candidate)
	const { isMobile } = useSelector(state => state.settings)
	const { users, page, sort, size, totalPages, serverStatus, error } =
		useSelector(state => state.users)

	const handleOpenPopup = () => {
		openPopup(<UploadFileProgressPopup type={'csv'} />)
	}

	const handleClickAction = async (user, action) => {
		const id = user.id

		if (action === 'view') {
			navigate(`view/${id}`, { state: { id } })
		} else if (action === 'edit') {
			navigate(`edit/${id}`, { state: { id } })
		}
	}

	const handleClickAddNew = () => {
		navigate('create')
	}

	const handleClickDeactivate = user => {
		console.log('Deactivate user with id: ', user.id)
	}

	const handleClickDeactivateAll = () => {
		console.log('Deactivated check users!')
	}

	const handleCheckFilter = () => {
		console.log('Show inactive users!')
	}

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const controls = [
		{
			id: 0,
			button: (
				<ControlButton
					color='var(--green)'
					txt={'Add New'}
					onClick={() => handleClickAddNew()}
				/>
			),
		},
		{
			id: 1,
			button: (
				<ControlButton
					color='var(--red)'
					txt={'Deactivate'}
					onClick={() => handleClickDeactivateAll()}
				/>
			),
		},
		{
			id: 2,
			button: (
				<ControlButton
					color='var(--violetLight)'
					txt={'Upload Data'}
					onClick={() => handleOpenPopup()}
				/>
			),
		},
	]

	const columns = useMemo(() => {
		const baseColumns = [
			{ Header: 'Name', accessor: 'name', width: 225 },
			{ Header: 'Email', accessor: 'email', width: 340 },
			{
				Header: 'Created At',
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					const formattedDate = moment(value).format('YYYY-MM-DD')
					return <span>{formattedDate}</span>
				},
				width: 175,
			},
			{ Header: 'Role', accessor: 'role', width: 185 },
			{ Header: 'Product Line', accessor: 'product_line', width: 225 },
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div
						style={demo ? { justifyContent: 'flex-end' } : {}}
						className='flex gap-[8rem]'
					>
						<ControlButton
							color='var(--orange)'
							txt={'View'}
							onClick={() => handleClickAction(row.original, 'view')}
						/>
						{!demo && (
							<>
								<ControlButton
									color='var(--blueLight)'
									txt={'Edit'}
									onClick={() => handleClickAction(row.original, 'edit')}
								/>
								<ControlButton
									color='var(--red)'
									txt={'Deactivate'}
									onClick={() => handleClickDeactivate(row.original)}
								/>
							</>
						)}
					</div>
				),
				width: 285,
			},
		]

		if (!demo) {
			baseColumns.unshift({
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 35,
			})
		}

		return baseColumns
	}, [demo])

	useEffect(() => {
		dispatch(getUsers({ page, size }))
	}, [page, size, sort])

	useEffect(() => {
		return () => {
			dispatch(clearUsers())
		}
	}, [location, dispatch])

	return (
		<ScreenLayout controlsList={!demo && controls} title={'Users'}>
			{!isMobile ? (
				<TableLayout
					serverStatus={serverStatus}
					serverError={error}
					nextPage={goToNextPage}
					previousPage={goToPreviousPage}
					totalPages={totalPages}
					dataSize={size}
					currentPage={page}
					toPage={goToPage}
					selectSize={handleSelectSize}
					columns={columns}
					data={users}
					filterCheckbox={
						!demo && (
							<RootCheckbox
								onChange={() => handleCheckFilter()}
								label={'Show Inactive Users'}
							/>
						)
					}
				/>
			) : (
				<TableMobileLayout
					totalPages={totalPages}
					toPage={goToPage}
					columns={columns}
					data={users}
					dataSort={sort}
					selectSort={handleSelectSort}
					actions={users.map(user => (
						<div key={user.id} className='flex gap-[8rem]'>
							<ControlButton
								color='var(--orange)'
								txt={'View'}
								onClick={() => handleClickAction(user, 'view')}
							/>

							{!demo && (
								<>
									<ControlButton
										color='var(--blueLight)'
										txt={'Edit'}
										onClick={() => handleClickAction(user, 'edit')}
									/>

									<ControlButton
										color='var(--red)'
										txt={'Deactivate'}
										onClick={() => handleClickDeactivate(user)}
									/>
								</>
							)}
						</div>
					))}
				/>
			)}
		</ScreenLayout>
	)
}
