import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearEditUser,
	getEditUser,
	setEditUser,
	setIsUserEdit,
	updateUser,
} from '@/redux/slices/userSlices/editUserSlice'
import {
	clearProductOptions,
	getCountries,
	getProductLine,
} from '@/redux/slices/productSlices/productOptionsSlice'

import { isEqual } from '@/helpers/helpers'
import { usePopup } from '../InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components//ui/general/Spinner'
import { EditInput } from '@/components/ui/inputs/EditInput'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { ChangePassPopup } from '@/popups/requestPopups/ChangePassPopup'
import { FillDataPopup } from '@/popups/attentionPopups/FillDataPopup'
import { SuccessfulSavePopup } from '@/popups/attentionPopups/SuccessfulSavePopup'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import UserImage from '@/components/ui/general/UserImage'

import photoImage from '@/assets/images/cards/photo.svg'
import styles from './styles.module.scss'

const UserEditField = ({ label, value, onChange, placeholder }) => (
	<li>
		<RootDesc>
			<b className='block min-w-[150rem]'>{label}</b>
		</RootDesc>

		<EditInput
			width={535}
			placeholder={placeholder}
			type='text'
			value={value}
			onChange={onChange}
		/>
	</li>
)

export const UserEditLayout = () => {
	const dispatch = useDispatch()

	const { openPopup } = usePopup()
	const id = useParams().id

	const { isMobile } = useSelector(state => state.settings)
	const { role } = useSelector(state => state.candidate)
	const { countries, productLine } = useSelector(state => state.productOptions)
	const { editUser, initialEditUser, serverStatus } = useSelector(
		state => state.editUser
	)

	const [photoFile, setPhotoFile] = useState(null)

	const updateUserField = (fieldName, value) => {
		dispatch(setEditUser({ ...editUser, [fieldName]: value }))
	}

	const handleClickImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickChangePass = () => {
		openPopup(<ChangePassPopup />)
	}

	const handleClickSave = async () => {
		const isAdmin = editUser.role === 'Admin'
		const requiredFields = ['name', 'lastname', 'email']

		if (!isAdmin) {
			requiredFields.push('country_id', 'product_line_id')
		}

		if (requiredFields.some(field => editUser[field] === '')) {
			openPopup(<FillDataPopup />)

			return
		}

		const user = {
			user: {
				name: editUser.name,
				lastname: editUser.lastname,
				email: editUser.email,
				cover: photoFile,
				...(role === 'Admin' && {
					password: editUser.password,
					password_confirmation: editUser.password_confirmation,
				}),
				...(!isAdmin && {
					country_id: editUser.country_id,
					product_line_id: editUser.product_line_id,
				}),
			},
		}

		const resultAction = await dispatch(updateUser({ id, user }))

		if (updateUser.fulfilled.match(resultAction)) {
			openPopup(<SuccessfulSavePopup title={'user'} />)
		} else {
			dispatch(setError('Save error!'))
		}
	}

	useEffect(() => {
		if (editUser && initialEditUser) {
			dispatch(setIsUserEdit(!isEqual(editUser, initialEditUser)))
		}
	}, [editUser, initialEditUser, dispatch])

	useEffect(() => {
		dispatch(getEditUser({ id }))
		dispatch(getCountries())
		dispatch(getProductLine())

		return () => {
			dispatch(clearProductOptions())
			dispatch(clearEditUser())
		}
	}, [dispatch, id])

	useEffect(() => {
		return () => {
			if (photoFile) {
				URL.revokeObjectURL(photoFile)
			}
		}
	}, [photoFile])

	return (
		<div className={styles.userWrapper}>
			<div className={styles.userHead}>
				<BackButton />
			</div>

			{serverStatus === 'loading' ? (
				<Spinner width={80} height={80} />
			) : (
				<div className={`${styles.user} ${styles.userBig}`}>
					<div className={styles.userInfo}>
						<div className={styles.userName}>
							<EditInput
								placeholder={`User name*`}
								width={!isMobile ? 350 : 210}
								label='user-name'
								type='text'
								value={editUser?.name}
								onChange={e => updateUserField('name', e.target.value)}
							/>

							<ControlButton
								color={'var(--green)'}
								txt={'Save'}
								onClick={handleClickSave}
							/>
						</div>

						<UserImage
							photoFile={photoFile || editUser?.metadata}
							editUser={editUser}
							photoImage={photoImage}
							onClick={handleClickImage}
						/>
					</div>

					<div className={styles.content}>
						<ul>
							<UserEditField
								label={`Last Name*`}
								value={editUser?.lastname}
								onChange={e => updateUserField('lastname', e.target.value)}
							/>

							<UserEditField
								label={`Email*`}
								value={editUser?.email}
								onChange={e => updateUserField('email', e.target.value)}
							/>

							{role === 'Admin' ? (
								<>
									<UserEditField
										label={`Password`}
										value={editUser?.password}
										onChange={e => updateUserField('password', e.target.value)}
										placeholder='*********'
									/>

									<UserEditField
										label={`Сonfirm Password`}
										value={editUser?.password_confirmation}
										onChange={e =>
											updateUserField('password_confirmation', e.target.value)
										}
										placeholder='*********'
									/>
								</>
							) : (
								<li>
									<RootDesc>
										<b className='block min-w-[150rem]'>Password</b>
									</RootDesc>

									<div className={styles.userField}>
										<RootDesc>
											<span>
												**********
												<i className={styles.userEditPassword}>
													<ControlButton
														color='var(--blue)'
														txt={'Edit'}
														onClick={handleClickChangePass}
													/>
												</i>
											</span>
										</RootDesc>
									</div>
								</li>
							)}

							<li>
								<RootDesc>
									<b className='block min-w-[150rem]'>Default Role</b>
								</RootDesc>

								<div className={styles.userField}>
									<RootDesc>
										<span>{editUser?.role}</span>
									</RootDesc>
								</div>
							</li>

							{editUser?.role !== 'Admin' && (
								<>
									<li>
										<RootDesc>
											<b className='block min-w-[150rem]'>
												Product Line<sup>*</sup>
											</b>
										</RootDesc>

										{productLine && productLine.length > 0 && (
											<EditSelect
												width={350}
												searchPlaceholder='Search Product Line'
												activeOption={editUser?.product_line}
												options={productLine}
												onSelect={selectedOption =>
													updateUserField('product_line_id', selectedOption.id)
												}
											/>
										)}
									</li>

									<li>
										<RootDesc>
											<b className='block min-w-[150rem]'>
												Country<sup>*</sup>
											</b>
										</RootDesc>

										{countries && countries.length > 0 && (
											<EditSelect
												width={350}
												searchPlaceholder='Search Country'
												activeOption={editUser?.country}
												options={countries}
												onSelect={selectedOption =>
													updateUserField('country_id', selectedOption.id)
												}
											/>
										)}
									</li>
								</>
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	)
}
