import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProductsService from '@/services/ProductsService'

export const initialCreateProduct = {
	product: {
		name: '',
		country: '',
		product_line: '',
		country_id: null,
		product_line_id: null,
		mask: '',
	},
	product_version: {
		questions: '',
	},
	error: '',
	serverStatus: '',
}

export const postCreateProduct = createAsyncThunk(
	'product/create',
	async ({ product }) => {
		try {
			await ProductsService.createProduct({ product })
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isProductCreate: false,
	saveProductCreate: false,
	error: '',
	serverStatus: '',
	createProduct: initialCreateProduct,
}

const createProductSlice = createSlice({
	name: 'create-product',
	initialState,
	reducers: {
		setIsProductCreate(state, action) {
			state.isProductCreate = action.payload
		},
		setSaveProductCreate(state, action) {
			state.saveProductCreate = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setCreateProduct(state, action) {
			state.createProduct = action.payload
		},
		clearCreateProduct() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(postCreateProduct.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(postCreateProduct.fulfilled, (state, action) => {
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(postCreateProduct.rejected, (state, action) => {
				state.error = 'Don`t create product!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setIsProductCreate,
	setCreateProduct,
	setSaveProductCreate,
	setError,
	setServerStatus,
	clearCreateProduct,
} = createProductSlice.actions
export default createProductSlice.reducer
