import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ScenariosService from '@/services/ScenariosService'

export const getProducts = createAsyncThunk('scenario/products', async () => {
	try {
		const response = await ScenariosService.getProducts()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	products: [],
	error: '',
	serverStatus: '',
}

const scenarioOptionsSlice = createSlice({
	name: 'scenario-options',
	initialState,
	reducers: {
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearScenarioOptions() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getProducts.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getProducts.fulfilled, (state, action) => {
				state.products = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getProducts.rejected, (state, action) => {
				state.error = 'Don`t get products!'
				state.serverStatus = 'error'
			})
	},
})

export const { setError, setServerStatus, clearScenarioOptions } =
	scenarioOptionsSlice.actions
export default scenarioOptionsSlice.reducer
