import React from 'react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

export const EditInput = props => {
	const { isMobile } = useSelector(state => state.settings)
	const { width, label, placeholder, type, value, required, onChange } = props

	return (
		<input
			placeholder={placeholder}
			className={styles.editInput}
			type={type}
			value={value}
			onChange={onChange}
			aria-label={label}
			required={required}
			style={!isMobile ? { width: `${width}rem` } : { width: `100%` }}
		/>
	)
}
