import React from 'react'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Link } from 'react-router-dom'
import { Logo } from '@/components/ui/general/Logo'
import { footerLinkList } from '@/helpers/constants'

import styles from './styles.module.scss'

export const Footer = () => {
	return (
		<div className={styles.footerWrapper}>
			<div className='container-big phone:p-0'>
				<div className={styles.footer}>
					<div className={styles.footerInfo}>
						<Logo direction={'footer'} />

						<RootDesc>
							<span className='text-[var(--white)]'>
								© 2024 Vivanti SA. All rights reserved.
							</span>
						</RootDesc>
					</div>

					<div className={styles.footerContent}>
						<h2>
							Ava.vivanti.eu is a service of Vivanti SA, specializing in
							AI-driven simulations <br /> to enhance commercial excellence in
							the pharmaceutical industry.
						</h2>

						<div className={styles.contentBottom}>
							<ul>
								{footerLinkList.map(item => (
									<li key={item.id}>
										<RootDesc>
											<Link to={item.link}>{item.name}</Link>
										</RootDesc>
									</li>
								))}
							</ul>

							<RootDesc>
								<span className='text-[var(--white)]'>
									For inquiries, contact us at:{' '}
									<a target='_blank' href='mailto:info@vivanti.eu'>
										info@vivanti.eu
									</a>
								</span>
							</RootDesc>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
