import React from 'react'
import styles from './styles.module.scss'

export const FillDataPopup = () => {
	return (
		<div className={styles.fillData}>
			<h2>
				Please ensure all required fields marked <br /> with an asterisk * are
				filled out before submitting!
			</h2>
		</div>
	)
}
