import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setViewTabsId } from '@/redux/slices/simulationsSlice'

import logoImage from '@/assets/images/general/logo.svg'
import logoImageWhite from '@/assets/images/general/logo-white.svg'
import styles from './styles.module.scss'

export const Logo = ({ direction }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const { auth } = useSelector(state => state.candidate)

	const handleClickLogo = () => {
		if (auth) {
			navigate('/simulations/call')
			dispatch(setViewTabsId(0))
		} else {
			navigate('/')
		}
	}

	if (direction === 'footer') {
		return (
			<div className={styles.footerLogo}>
				<img onClick={handleClickLogo} src={logoImageWhite} alt='logo' />
			</div>
		)
	} else {
		return (
			<div
				className={
					location.pathname.includes('login') ? styles.logoHero : styles.logo
				}
			>
				<img onClick={handleClickLogo} src={logoImage} alt='logo' />
			</div>
		)
	}
}
