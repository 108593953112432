import React, { useEffect, useRef } from 'react'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '@/components//ui/general/Spinner'
import { WarningData } from '@/components/ui/general/WarningData'
import { TableSort } from '@/components/ui/inputs/TableSort'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

export const TableMobileLayout = props => {
	const {
		toPage,
		selectSort,
		dataSort,
		totalPages,
		columns,
		data,
		actions,
		serverStatus,
	} = props

	const tableWrapperRef = useRef(null)
	const { isMobile } = useSelector(state => state.settings)

	const options = columns
		? columns
				.map(column => column.Header)
				.filter(
					header =>
						typeof header === 'string' &&
						header !== 'Actions' &&
						header !== 'Products'
				)
		: []

	useEffect(() => {
		if (tableWrapperRef.current && isMobile) {
			tableWrapperRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [data])

	return (
		<div ref={tableWrapperRef} className={styles.tableWrapper}>
			{serverStatus === 'loading' ? (
				<Spinner width={80} height={80} />
			) : data?.length === 0 ? (
				<WarningData type={'empty'} />
			) : !data && serverStatus === 'error' ? (
				<WarningData type={'error'} />
			) : (
				<>
					<TableSort
						options={options}
						onSelect={selectSort}
						activeOption={dataSort}
					/>

					<div className={styles.table}>
						{data &&
							data.map((item, index) => (
								<li key={item.id} className={styles.tableCard}>
									<ul>
										{columns &&
											columns.map(column => (
												<li key={column.Header}>
													<RootDesc>
														<b>{column.Header}</b>
													</RootDesc>
													{column.Header === 'Actions' ? (
														actions[index]
													) : typeof column.Header !== 'string' ? (
														<></>
													) : column.Header === 'Date' ||
													  column.Header === 'Created At' ? (
														<RootDesc>
															<span>
																{moment(item.created_at).format('YYYY-MM-DD')}
															</span>
														</RootDesc>
													) : column.Header === 'Status' ? (
														<RootDesc>
															<span>Created</span>
														</RootDesc>
													) : (
														<RootDesc>
															<span>{item[column.accessor]}</span>
														</RootDesc>
													)}
												</li>
											))}
									</ul>
								</li>
							))}
					</div>
				</>
			)}

			{data?.length !== 0 && (
				<ReactPaginate
					containerClassName={styles.tableControls}
					pageLinkClassName={styles.pageLink}
					activeClassName={styles.activePage}
					previousClassName={styles.previousPage}
					nextClassName={styles.nextPage}
					disabledClassName={styles.disabledButton}
					renderOnZeroPageCount={null}
					pageRangeDisplayed={1}
					marginPagesDisplayed={1}
					pageCount={totalPages}
					onPageChange={item => toPage(item.selected)}
					breakLabel='...'
					nextLabel={
						<button>
							{/* <RootDesc>
								<span>Next</span>
							</RootDesc> */}
							<i
								style={{
									position: 'relative',
									left: '1rem',
									borderTop: '5rem solid transparent',
									borderBottom: '5rem solid transparent',
									borderLeft: '9rem solid var(--blueLight)',
								}}
							></i>
						</button>
					}
					previousLabel={
						<button>
							<i
								style={{
									position: 'relative',
									left: '-1rem',
									borderTop: '5rem solid transparent',
									borderBottom: '5rem solid transparent',
									borderRight: '9rem solid var(--blueLight)',
								}}
							></i>
							{/* <RootDesc>
								<span>Prev</span>
							</RootDesc> */}
						</button>
					}
				/>
			)}
		</div>
	)
}
