import $api from '../http'

export default class ProductsService {
	static async getCountries() {
		return $api.get(`/api/v1/countries`)
	}

	static async getProductLine() {
		return $api.get(`/api/v1/product_lines`)
	}

	static async getProducts({ page, size }) {
		return $api.get(`/api/v1/products?page=${page}&page_size=${size}`)
	}

	static async getAllProducts() {
		return $api.get(`/api/v1/products`)
	}

	static async viewProduct({ id }) {
		return $api.get(`/api/v1/products/${id}`)
	}

	static async createProduct({ product }) {
		return $api.post(
			`/api/v1/products`,
			{ ...product },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}

	static async updateProduct({ id, product }) {
		return $api.patch(
			`/api/v1/products/${id}`,
			{ ...product },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}
}
