import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import styles from './styles.module.scss'

export const ControlButton = props => {
	const { txt, iconId, type, onClick, disabled, color, hoverColor } = props

	return (
		<button
			className={styles.controlButton}
			type={type || 'button'}
			onClick={onClick}
			disabled={disabled}
			style={{
				borderColor: color || 'var(--black)',
				color: color || 'inherit',
				'--color': color || 'transparent',
				'--hoverColor': hoverColor || 'var(--white)',
			}}
		>
			{iconId && <RootIcon width={16} height={16} id={iconId} />}

			<SmallDesc>
				<span>{txt}</span>
			</SmallDesc>
		</button>
	)
}
