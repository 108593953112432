import React from 'react'
import styles from './styles.module.scss'
import { H3 } from '../../titles/H3'
import { RootDesc } from '../../descriptions/RootDesc'

export const WarningData = ({ type }) => {
	if (type === 'error') {
		return (
			<div className={styles.warningData}>
				<div className='text-[var(--red)]'>
					<H3>Error receiving data from server!</H3>
				</div>

				<RootDesc>
					<span className='text-[var(--grey)]'>
						Try again later or contact our administrator...
					</span>
				</RootDesc>
			</div>
		)
	} else if (type === 'empty') {
		return (
			<div className={styles.warningData}>
				<H3>There are no new data at the moment!</H3>

				<RootDesc>
					<span className='text-[var(--grey)]'>
						New data will be displayed here...
					</span>
				</RootDesc>
			</div>
		)
	} else {
		return (
			<div className={styles.warningData}>
				<H3>There are no new data at the moment!</H3>

				<RootDesc>
					<span className='text-[var(--grey)]'>
						New data will be displayed here...
					</span>
				</RootDesc>
			</div>
		)
	}
}
