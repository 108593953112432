import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const presentationRequest = createAsyncThunk(
	'request/presentation',
	async ({ name, phone, email, message }) => {
		try {
			await axios.post(`/guest/requests/presentation`, {
				name,
				phone,
				email,
				message,
			})
		} catch (e) {
			console.log(err)
		}
	}
)

const initialState = {
	name: '',
	phone: '',
	email: '',
	message: '',
	agree: false,
	error: '',
	serverStatus: '',
}

const presentationSlice = createSlice({
	name: 'presentation-form',
	initialState,
	reducers: {
		setName(state, action) {
			state.name = action.payload
		},
		setPhone(state, action) {
			state.phone = action.payload
		},
		setEmail(state, action) {
			state.email = action.payload
		},
		setMessage(state, action) {
			state.message = action.payload
		},
		setAgree(state, action) {
			state.agree = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearRequestForm() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(presentationRequest.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(presentationRequest.fulfilled, (state, action) => {
				state.name = ''
				state.phone = ''
				state.email = ''
				state.message = ''
				state.agree = false
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(presentationRequest.rejected, (state, action) => {
				state.error = 'Don`t send!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setName,
	setPhone,
	setEmail,
	setMessage,
	setAgree,
	setError,
	setServerStatus,
	clearRequestForm,
} = presentationSlice.actions
export default presentationSlice.reducer
