import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	setPhone,
	setError,
	setName,
	setEmail,
	setMessage,
	setAgree,
	clearRequestForm,
	presentationRequest,
} from '@/redux/slices/requestSlices/presentationSlice'

import PhoneInput from 'react-phone-input-2'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { H3 } from '@/components/ui/titles/H3'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '@/components//ui/general/Spinner'
import { FormError } from '@/components/ui/general/FormError'

import styles from './styles.module.scss'
import 'react-phone-input-2/lib/style.css'

export const PresentationPopup = () => {
	const { name, phone, email, message, agree, error, serverStatus } =
		useSelector(state => state.message)
	const dispatch = useDispatch()
	const { closePopup } = usePopup()

	const handleSubmit = async e => {
		e.preventDefault()
		dispatch(setError(''))

		if (!name || !phone || !email || !agree) {
			dispatch(setError('Please fill out all fields!'))
			return
		}

		const resultAction = await dispatch(
			presentationRequest({ name, phone, email, message })
		)

		if (presentationRequest.fulfilled.match(resultAction)) {
			dispatch(clearRequestForm())
		} else {
			dispatch(setError('Error sending data!'))
		}
	}

	useEffect(() => {
		dispatch(setError(''))
	}, [name, phone, email, message, agree])

	useEffect(() => {
		return () => {
			dispatch(clearRequestForm())
		}
	}, [])

	return (
		<div className={styles.call}>
			<H3>Request a Presentation</H3>

			<form onSubmit={handleSubmit} className={styles.callForm}>
				<div className={styles.callInput}>
					<RootInput
						width={376}
						label={'Name'}
						type={'text'}
						value={name}
						required={true}
						onChange={e => dispatch(setName(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callInputPhone}>
					<RootDesc>
						<b>Phone</b>
					</RootDesc>

					<PhoneInput
						searchPlaceholder='Search'
						disableSearchIcon={true}
						enableSearch={true}
						country={'us'}
						value={phone}
						onChange={number => dispatch(setPhone(number))}
						inputProps={{
							name: 'phone',
							required: true,
						}}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callInput}>
					<RootInput
						width={376}
						label={'Email'}
						type={'email'}
						value={email}
						required={true}
						onChange={e => dispatch(setEmail(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callInput}>
					<RootTextarea
						placeholder={'Optional'}
						label={'Message'}
						type={'text'}
						value={message}
						required={false}
						onChange={e => dispatch(setMessage(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.callCheckbox}>
					<RootCheckbox
						fontSize={14}
						checked={agree}
						onChange={e => dispatch(setAgree(e.target.checked))}
						label={
							<>
								By clicking you agree to our{' '}
								<Link onClick={() => closePopup()} to={'/privacy'}>
									Privacy Statement
								</Link>
							</>
						}
					/>
				</div>

				<RootButton
					txt={'Submit'}
					type={'submit'}
					disabled={
						!name || !phone || !email || !agree || serverStatus === 'loading'
					}
				/>

				{error !== '' && <FormError error={error} bottom={105} />}

				{serverStatus === 'loading' && <Spinner width={50} height={50} />}
			</form>
		</div>
	)
}
