import React from 'react'
import { usePopup } from './PopupContext'
import { Overlay } from '../Overlay'
import { RootPopup } from '../RootPopup'

export const InfoPopup = ({ width }) => {
	const { popupContent, closePopup } = usePopup()

	return (
		<>
			{popupContent.content && (
				<Overlay>
					<RootPopup
						width={width}
						close={popupContent.closeButton}
						onClick={closePopup}
					>
						{popupContent.content}
					</RootPopup>
				</Overlay>
			)}
		</>
	)
}
