import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import VideosService from '@/services/VideosService'

export const viewVideo = createAsyncThunk('video/view', async ({ id }) => {
	try {
		const response = await VideosService.viewVideo({ id })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	video: null,
	error: '',
	serverStatus: '',
}

const viewVideoSlice = createSlice({
	name: 'view-video',
	initialState,
	reducers: {
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearViewVideo() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(viewVideo.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewVideo.fulfilled, (state, action) => {
				state.video = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewVideo.rejected, state => {
				state.error = 'Don`t view video!'
				state.serverStatus = 'error'
			})
	},
})

export const { clearViewVideo, setError, setServerStatus } =
	viewVideoSlice.actions
export default viewVideoSlice.reducer
