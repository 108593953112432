import React from 'react'
import styles from './styles.module.scss'
import { ControlButton } from '@/components/ui/buttons/ControlButton'

export const UnsavedPopup = props => {
	const { onClickYes, onClickNo } = props

	return (
		<div className={styles.unsavedPopup}>
			<h2>
				It seems there are unsaved changes. Are you sure <br /> you want to exit
				without saving?
			</h2>

			<div className={styles.buttons}>
				<ControlButton
					color='var(--red)'
					txt={'Yes, Exit'}
					onClick={onClickYes}
				/>

				<ControlButton
					color='var(--green)'
					txt={'No, Stay'}
					onClick={onClickNo}
				/>
			</div>
		</div>
	)
}
