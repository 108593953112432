import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import UploadFilesService from '@/services/UploadFilesService'

const handleFileUpload = async (uploadFunction, file, controller, dispatch) => {
	const newFile = new FormData()
	newFile.append('file', file)

	const signal = controller.signal

	const onUploadProgress = progressEvent => {
		const percentCompleted = Math.round(
			(progressEvent.loaded * 100) / progressEvent.total
		)

		dispatch(updateProgress(percentCompleted))
	}

	try {
		const response = await uploadFunction(newFile, signal, onUploadProgress)

		return response.data
	} catch (error) {
		if (axios.isCancel(error)) {
			throw new Error('Upload canceled!')
		}

		throw new Error(error.response?.data || 'Upload failed!')
	}
}

export const uploadFile = createAsyncThunk(
	'file/uploadFile',
	async ({ file, controller }, { rejectWithValue, dispatch }) => {
		try {
			return await handleFileUpload(
				UploadFilesService.uploadUsers,
				file,
				controller,
				dispatch
			)
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

export const uploadAvatar = createAsyncThunk(
	'file/uploadAvatar',
	async ({ file, controller }, { rejectWithValue, dispatch }) => {
		try {
			return await handleFileUpload(
				UploadFilesService.uploadAvatar,
				file,
				controller,
				dispatch
			)
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

const fileUploadSlice = createSlice({
	name: 'fileUpload',
	initialState: {
		progress: 0,
		uploading: false,
		error: null,
	},
	reducers: {
		updateProgress(state, action) {
			state.progress = action.payload
		},
		resetUpload(state) {
			state.progress = 0
			state.uploading = false
			state.error = null
		},
	},
	extraReducers: builder => {
		builder
			.addCase(uploadFile.pending, state => {
				state.uploading = true
				state.progress = 0
				state.error = null
			})
			.addCase(uploadFile.fulfilled, state => {
				state.uploading = true
				state.progress = 100
			})
			.addCase(uploadFile.rejected, (state, action) => {
				state.uploading = true
				state.error = action.payload
			})

			.addCase(uploadAvatar.pending, state => {
				state.uploading = true
				state.progress = 0
				state.error = null
			})
			.addCase(uploadAvatar.fulfilled, state => {
				state.uploading = true
				state.progress = 100
			})
			.addCase(uploadAvatar.rejected, (state, action) => {
				state.uploading = true
				state.error = action.payload
			})
	},
})

export const { updateProgress, resetUpload } = fileUploadSlice.actions
export default fileUploadSlice.reducer
