import React from 'react'
import { useSelector } from 'react-redux'

import { descriptionLevelList } from '@/helpers/constants'
import { capitalize } from '@/helpers/helpers'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const LevelPopup = props => {
	const { item, close } = props
	const { serverStatus } = useSelector(state => state.avatar)

	return (
		<div className={styles.levelPopup}>
			<h2>{capitalize(item.name)} Level</h2>

			<H3>Welcome to the Simulation!</H3>

			<RootDesc>
				<span className='block text-center'>
					{descriptionLevelList[item.id].text}
				</span>
			</RootDesc>

			{serverStatus === 'loading' ? (
				<div className={styles.spinner}>
					<RootIcon width={26} height={26} id={'spinner-upload'} />
				</div>
			) : (
				<RootButton txt={'Continue'} type={'button'} onClick={close} />
			)}
		</div>
	)
}
