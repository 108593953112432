import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersService from '@/services/UsersService'

export const viewUser = createAsyncThunk('user/view', async ({ id }) => {
	try {
		const response = await UsersService.viewUser({ id })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	user: null,
	error: '',
	serverStatus: '',
}

const viewUserSlice = createSlice({
	name: 'view-user',
	initialState,
	reducers: {
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearViewUser() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(viewUser.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewUser.fulfilled, (state, action) => {
				state.user = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewUser.rejected, (state, action) => {
				state.error = 'Don`t view user!'
				state.serverStatus = 'error'
			})
	},
})

export const { clearViewUser, setError, setServerStatus } =
	viewUserSlice.actions
export default viewUserSlice.reducer
