import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearCreateScenario,
	initialCreateScenario,
	postCreateScenario,
	setCreateScenario,
	setIsScenarioCreate,
	setError,
} from '@/redux/slices/scenarioSlices/createScenarioSlice'
import {
	clearScenarioOptions,
	getProducts,
} from '@/redux/slices/scenarioSlices/scenarioOptionsSlice'
import { v4 as uuidv4 } from 'uuid'

import { isEqual } from '@/helpers/helpers'
import { usePopup } from '../InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { EditInput } from '@/components/ui/inputs/EditInput'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { UploadFilePopup } from '@/popups/uploadPopups/UploadFilePopup'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { FillDataPopup } from '@/popups/attentionPopups/FillDataPopup'
import { SuccessfulSavePopup } from '@/popups/attentionPopups/SuccessfulSavePopup'
import { MaskList } from '@/components/ui/general/MaskList'
import { DocumentPreview } from '@/components/ui/general/DocumentPreview'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { DropCalendar } from '@/components/ui/general/DropCalendar'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { Spinner } from '@/components//ui/general/Spinner'
import { PreviewCard } from '@/components/ui/general/PreviewCard'

import styles from './styles.module.scss'

const ScenarioEditField = ({ label, value, onChange }) => (
	<li>
		<RootDesc>
			<b className='block w-[280rem] phone:w-[100%]'>{label}</b>
		</RootDesc>

		<RootTextarea type={'text'} value={value} onChange={onChange} />
	</li>
)

export const ScenarioCreateLayout = () => {
	const { openPopup } = usePopup()
	const dispatch = useDispatch()

	const { isMobile } = useSelector(state => state.settings)
	const { createScenario, serverStatus } = useSelector(
		state => state.createScenario
	)
	const { products } = useSelector(state => state.scenarioOptions)

	const [fileList, setFileList] = useState([])
	const [photoFile, setPhotoFile] = useState(null)
	const [dueDate, setDueDate] = useState(new Date())

	const scenarioRef = useRef()

	const updateScenarioField = useCallback(
		(fieldType, value) => {
			dispatch(
				setCreateScenario({
					...createScenario,
					[fieldType]: value,
				})
			)
		},
		[createScenario, dispatch]
	)

	const handleChangeDate = currentDate => {
		setDueDate(currentDate)
	}

	const handleClickCover = useCallback(
		color => {
			updateScenarioField('mask', color)
		},
		[updateScenarioField]
	)

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickUploadFile = () => {
		openPopup(<UploadFilePopup fileList={fileList} setFileList={setFileList} />)
	}

	const handleClickRemoveFile = fileName => {
		setFileList(prevFileList =>
			prevFileList.filter(file => file.file.name !== fileName)
		)
	}

	const handleClickRemovePhoto = () => {
		setPhotoFile(null)
	}

	const handleSelect = selectedOption => {
		const productId = selectedOption.id
		const currentProductIds = createScenario.product_ids || []

		if (currentProductIds.includes(productId)) {
			updateScenarioField(
				'product_ids',
				currentProductIds.filter(id => id !== productId)
			)
		} else {
			updateScenarioField('product_ids', [...currentProductIds, productId])
		}
	}

	const handleClickSave = async () => {
		if (
			createScenario?.product_ids.length === 0 ||
			createScenario.mask === '' ||
			createScenario.name === '' ||
			createScenario?.purpose === '' ||
			createScenario?.objectives === ''
		) {
			openPopup(<FillDataPopup />)
		} else {
			const scenario = {
				scenario: {
					name: createScenario.name,
					cover: photoFile,
					files: fileList,
					mask: createScenario.mask,
					product_ids: createScenario.product_ids,
					purpose: createScenario.purpose,
					objectives: createScenario.objectives,
					focus: createScenario.focus,
					key_messages: createScenario.key_messages,
					due_date: dueDate,
					structure: createScenario.structure,
					script: createScenario.script,
					questions: createScenario.questions,
				},
			}

			console.log(dueDate)

			const resultAction = await dispatch(postCreateScenario({ scenario }))

			if (postCreateScenario.fulfilled.match(resultAction)) {
				openPopup(<SuccessfulSavePopup title={'scenario'} />)

				setFileList([])
				setPhotoFile(null)
				setDueDate(new Date())
				dispatch(clearCreateScenario())
			} else {
				dispatch(setError('Save error!'))
			}
		}
	}

	useEffect(() => {
		dispatch(getProducts())

		return () => {
			dispatch(clearCreateScenario())
			dispatch(clearScenarioOptions())
		}
	}, [dispatch])

	useEffect(() => {
		dispatch(
			setIsScenarioCreate(!isEqual(createScenario, initialCreateScenario))
		)
	}, [createScenario, dispatch])

	useEffect(() => {
		return () => {
			if (photoFile) {
				URL.revokeObjectURL(photoFile)
			}
		}
	}, [photoFile])

	useEffect(() => {
		if (scenarioRef.current && isMobile) {
			scenarioRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={scenarioRef} className={styles.scenarioWrapper}>
			<BorderArticle>
				<div className={styles.scenario}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.scenarioContent}>
							<div className={styles.scenarioEditContent}>
								<div className={styles.scenarioContentHeadWrapper}>
									<div className={styles.scenarioContentHead}>
										<div className='flex items-center gap-[16rem]'>
											<EditInput
												width={!isMobile ? 350 : 210}
												placeholder={`Scenario name*`}
												label='scenario-name'
												type='text'
												value={createScenario?.name}
												onChange={e =>
													updateScenarioField('name', e.target.value)
												}
											/>

											<ControlButton
												color='var(--green)'
												txt={'Save'}
												onClick={() => handleClickSave()}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>
													Choose Background<sup>*</sup>
												</b>
											</RootDesc>

											<MaskList
												onClickCover={color => handleClickCover(color)}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>Cover</b>
											</RootDesc>

											<div className='flex flex-col items-start gap-[16rem]'>
												{photoFile && (
													<DocumentPreview
														name={photoFile?.name}
														onClickCross={() => handleClickRemovePhoto()}
													/>
												)}

												<ControlButton
													color='var(--violetLight)'
													txt={'Upload Image'}
													onClick={() => handleClickUploadImage()}
												/>
											</div>
										</div>
									</div>

									<div className='flex flex-col gap-[24rem] items-start relative z-[1]'>
										<RootDesc>
											<b>Cover Preview</b>
										</RootDesc>

										<PreviewCard
											name={createScenario?.name}
											mask={createScenario?.mask}
											image={
												photoFile ? URL.createObjectURL(photoFile) : undefined
											}
										/>
									</div>
								</div>
							</div>

							<ul
								className={styles.scenarioContentBody + ' ' + styles.editBody}
							>
								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Related Products<sup>*</sup>
										</b>
									</RootDesc>

									{products && products.length > 0 && (
										<div className='w-[100%]'>
											<EditSelect
												width={500}
												searchPlaceholder='Start typing Product Name'
												multiSelect={true}
												activeOption={[]}
												options={products}
												onSelect={selectedOption =>
													handleSelect(selectedOption)
												}
											/>
										</div>
									)}
								</li>

								<ScenarioEditField
									value={createScenario?.purpose}
									onChange={e => updateScenarioField('purpose', e.target.value)}
									label={
										<>
											Purpose of the Call<sup>*</sup>
										</>
									}
								/>

								<ScenarioEditField
									value={createScenario?.objectives}
									onChange={e =>
										updateScenarioField('objectives', e.target.value)
									}
									label={
										<>
											Objectives of the Call<sup>*</sup>
										</>
									}
								/>

								<ScenarioEditField
									value={createScenario?.focus}
									onChange={e => updateScenarioField('focus', e.target.value)}
									label={'Focus of the Call'}
								/>

								<ScenarioEditField
									value={createScenario?.key_messages}
									onChange={e =>
										updateScenarioField('key_messages', e.target.value)
									}
									label={
										<>
											Key Messages<sup>*</sup>
										</>
									}
								/>

								<ScenarioEditField
									value={createScenario?.structure}
									onChange={e =>
										updateScenarioField('structure', e.target.value)
									}
									label={'Call Structure'}
								/>

								<ScenarioEditField
									value={createScenario?.script}
									onChange={e => updateScenarioField('script', e.target.value)}
									label={'Call Script'}
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>Related Documents</b>
									</RootDesc>

									<div className={styles.scenarioDocuments}>
										{fileList && fileList.length > 0 && (
											<div className={styles.scenarioDocumentsPreview}>
												{fileList.map(file => (
													<div key={uuidv4()}>
														<DocumentPreview
															name={file?.file?.name}
															onClickCross={name => handleClickRemoveFile(name)}
														/>
													</div>
												))}
											</div>
										)}

										<ControlButton
											color='var(--violetLight)'
											txt={'Upload .txt'}
											onClick={() => handleClickUploadFile()}
										/>
									</div>
								</li>

								<ScenarioEditField
									value={createScenario?.questions}
									onChange={e =>
										updateScenarioField('questions', e.target.value)
									}
									label={'The most important questions for training:'}
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Due Date<sup>*</sup>
										</b>
									</RootDesc>

									<DropCalendar date={dueDate} setDate={handleChangeDate} />
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
