import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import {
	setMessages,
	setUserMessage,
	sendMessage,
	setIsRecording,
	setChatTime,
} from '@/redux/slices/avatarSlices/avatarSlice'
import SpeechRecognition, {
	useSpeechRecognition,
} from 'react-speech-recognition'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { BrowserSupportPopup } from '@/popups/simulationPopups/BrowserSupportPopup'
import { Scrollbar } from '@/components/ui/general/Scrollbar'

import recImage from '@/assets/images/chat/rec-message.svg'
import sendMessageImage from '@/assets/images/chat/sending-message.svg'
import styles from './styles.module.scss'
import { capitalize } from '../../helpers/helpers'

export const Chat = () => {
	const {
		finalTranscript,
		transcript,
		resetTranscript,
		isMicrophoneAvailable,
		browserSupportsSpeechRecognition,
		browserSupportsContinuousListening,
	} = useSpeechRecognition()

	const { openPopup } = usePopup()
	const dispatch = useDispatch()
	const location = useLocation()
	const level = location.state?.level

	const textareaRef = useRef(null)

	const {
		session_id,
		messages,
		userMessage,
		serverStatus,
		isRecording,
		isSessionActive,
		chatTime,
	} = useSelector(state => state.avatar)

	const [textareaHeight, setTextareaHeight] = useState('24rem')

	const [lastSpokenTime, setLastSpokenTime] = useState(Date.now())
	const [str, setStr] = useState([])
	const pauseThreshold = 750

	const handleChangeMessage = e => {
		setTextareaHeight('auto')
		setTextareaHeight(`${textareaRef.current.clientHeight}rem`)
		dispatch(setUserMessage(e.target.value))
	}

	const handleSendMessage = async e => {
		e.preventDefault()

		if (userMessage.trim() && userMessage.length !== 0) {
			const user = {
				messageId: uuidv4(),
				sender: 'user',
				text: userMessage,
			}

			dispatch(setMessages(user))

			const resultAction = await dispatch(
				sendMessage({ id: session_id, msg: user.text })
			)

			if (sendMessage.fulfilled.match(resultAction)) {
				if (chatTime === 0) {
					dispatch(setChatTime(level.remainingTime))
				}

				dispatch(setUserMessage(''))
				stopRecording()

				if (transcript) {
					resetTranscript()
				}
			} else {
				console.log('error')
			}
		}
	}

	const startRecording = () => {
		if (!browserSupportsSpeechRecognition) {
			dispatch(setIsRecording(false))

			openPopup(<BrowserSupportPopup />)
		} else {
			dispatch(setIsRecording(true))

			if (!isMicrophoneAvailable) {
				console.log('User denies access to the microphone.')
			}

			if (browserSupportsContinuousListening) {
				SpeechRecognition.startListening({
					continuous: true,
					language: 'en-US',
				})
			} else {
				SpeechRecognition.startListening()
			}
		}
	}

	const stopRecording = () => {
		dispatch(setIsRecording(false))

		SpeechRecognition.stopListening()
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault()
			handleSendMessage(e)
		}
	}

	// добавляем пунктуацию в transcript, pauseThreshold - время срабатывания паузы в речи
	// возможны баги
	useEffect(() => {
		const timer = setTimeout(() => {
			if (transcript && Date.now() - lastSpokenTime >= pauseThreshold) {
				// удаляет одинаковые слова но работает
				if (transcript === '') {
					dispatch(setUserMessage(''))
				} else {
					if (userMessage === '') {
						dispatch(setUserMessage(capitalize(transcript) + '. '))
					} else {
						const textWords = userMessage
							.replace(/\./g, '')
							.toLowerCase()
							.split(' ')
							.filter(word => word !== '')
						const finalTranscriptWords = transcript
							.replace(/\./g, '')
							.split(' ')
							.filter(word => word !== '')
						const newWords = finalTranscriptWords.filter(
							word => !textWords.includes(word.toLowerCase())
						)
						if (newWords.length > 0) {
							const newSentence = newWords.join(' ')
							const updatedText = userMessage.trim().endsWith('.')
								? userMessage + capitalize(newSentence) + '. '
								: userMessage + newSentence + '. '
							dispatch(setUserMessage(updatedText))
						}
					}
				}

				// работает не корректно
				// if (transcript === '') {
				// 	setStr([])
				// 	dispatch(setUserMessage(''))
				// } else {
				// 	if (userMessage === '') {
				// 		setStr(prevStr => [...prevStr, finalTranscript])
				// 		dispatch(setUserMessage(finalTranscript))
				// 	} else {
				// 		let transformedTranscript = ''

				// 		str.map(phrase => {
				// 			const regex = new RegExp(phrase, 'g')
				// 			transformedTranscript = finalTranscript.replace(regex, '')
				// 		})

				// 		transformedTranscript = transformedTranscript
				// 			.replace(/\s+/g, ' ')
				// 			.trim()

				// 		setStr(prevStr => [...prevStr, transformedTranscript])
				// 	}
				// }
			}
		}, pauseThreshold)

		return () => clearTimeout(timer)
	}, [transcript, lastSpokenTime])

	useEffect(() => {
		if (transcript) {
			setLastSpokenTime(Date.now())
		}
	}, [transcript])

	useEffect(() => {
		setTextareaHeight(
			userMessage ? `${textareaRef.current.scrollHeight}rem` : '24rem'
		)
	}, [userMessage])

	useEffect(() => {
		if (!isSessionActive) {
			stopRecording()
			resetTranscript()
		}
	}, [isSessionActive])

	useEffect(() => {
		return () => {
			stopRecording()
			resetTranscript()
			SpeechRecognition.abortListening()
		}
	}, [location])

	return (
		<BorderArticle>
			<div className={styles.gameChat}>
				<Scrollbar>
					<div className={styles.chat}>
						{messages &&
							messages.length > 0 &&
							messages.map((msg, index) => (
								<div
									key={index}
									className={
										msg.sender === 'bot'
											? styles.botMessage
											: styles.userMessage
									}
								>
									<RootDesc>
										{msg.sender === 'bot' ? (
											<b>{msg.text}</b>
										) : (
											<span>{msg.text}</span>
										)}
									</RootDesc>

									{msg.sender !== 'bot' && (
										<img
											className={styles.userMessageIcon}
											src={sendMessageImage}
											alt='sending'
										/>
									)}
								</div>
							))}
					</div>
				</Scrollbar>

				<div
					className={
						isSessionActive
							? styles.chatControls
							: styles.chatControls + ' ' + styles.chatControlsDisabled
					}
				>
					<div className={styles.textarea}>
						<textarea
							disabled={serverStatus === 'loading' ? true : false}
							ref={textareaRef}
							type={'text'}
							value={userMessage}
							onChange={handleChangeMessage}
							onKeyDown={handleKeyDown}
							style={{
								height: textareaHeight,
								maxHeight: '100rem',
								overflowY: 'scroll',
							}}
						/>
					</div>

					<div className={styles.chatControlsBtns}>
						<div
							onClick={handleSendMessage}
							style={serverStatus !== 'loading' ? { animation: 'none' } : {}}
							className={styles.spinner}
						>
							<RootIcon
								width={26}
								height={26}
								id={
									serverStatus === 'loading' ? 'spinner-upload' : 'send-message'
								}
							/>
						</div>

						<div
							className={
								!isRecording ? styles.rec : styles.rec + ' ' + styles.recActive
							}
							onClick={!isRecording ? startRecording : stopRecording}
						>
							<button
								disabled={serverStatus === 'loading' ? true : false}
								type='button'
							>
								<img src={recImage} alt='rec' />
							</button>
						</div>
					</div>
				</div>
			</div>
		</BorderArticle>
	)
}
