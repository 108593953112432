import React, { createContext, useContext, useState } from 'react'

const PopupContext = createContext()

export const usePopup = () => {
	return useContext(PopupContext)
}

export const PopupProvider = ({ children }) => {
	const [popupContent, setPopupContent] = useState({
		content: null,
		closeButton: true,
	})

	const openPopup = (content, options = { closeButton: true }) => {
		setPopupContent({ content, closeButton: options.closeButton })
	}

	const closePopup = () => {
		setPopupContent({ content: null, closeButton: true })
	}

	return (
		<PopupContext.Provider value={{ popupContent, openPopup, closePopup }}>
			{children}
		</PopupContext.Provider>
	)
}
