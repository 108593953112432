import React, { useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearViewSession,
	viewSession,
} from '@/redux/slices/sessionSlices/viewSessionSlice'
import moment from 'moment'
import { capitalize, statusVariant } from '@/helpers/helpers'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components//ui/general/Spinner'
import { Scrollbar } from '@/components/ui/general/Scrollbar'

import styles from './styles.module.scss'

const SessionField = ({ label, value }) => {
	return (
		<li>
			<RootDesc>
				<b className='block w-[150rem]'>{label}</b>
			</RootDesc>

			<RootDesc>
				<span>{value}</span>
			</RootDesc>
		</li>
	)
}

export const SessionViewLayout = () => {
	const dispatch = useDispatch()
	const location = useLocation()

	const id = useParams().id

	const { isMobile } = useSelector(state => state.settings)
	const { session, serverStatus } = useSelector(state => state.viewSession)
	const sessionRef = useRef()

	useEffect(() => {
		dispatch(viewSession({ id }))

		return () => {
			dispatch(clearViewSession())
		}
	}, [dispatch])

	useEffect(() => {
		if (sessionRef.current && isMobile) {
			sessionRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={sessionRef} className={styles.sessionWrapper}>
			<BorderArticle>
				<div className={styles.session}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.sessionContent}>
							<div className={styles.sessionContentHead}>
								<h2>
									{moment(session?.created_at).format(`YYYY-MM-DD`)}
									&nbsp;&nbsp;&nbsp;
									{moment(session?.created_at).format(`HH:mm:ss`)}
								</h2>
							</div>

							<ul className={styles.sessionContentBody}>
								<SessionField label={'Scanario'} value={session?.scenario} />

								<SessionField label={'User'} value={session?.user} />

								<SessionField
									label={'Products'}
									value={session?.scenario_products.join(', ')}
								/>

								<SessionField
									label={'Level'}
									value={capitalize(session?.level)}
								/>

								<SessionField
									label={'Status'}
									value={statusVariant(session?.score).grade}
								/>

								<SessionField label={'AI Feedback'} value={session?.feedback} />

								<li className='relative'>
									<RootDesc>
										<b className='block w-[150rem]'>Dialog Details</b>
									</RootDesc>

									<Scrollbar>
										<div className={styles.sessionMessages}>
											{session?.messages.map(message => (
												<div
													className={styles.sessionMessage}
													key={message?.id}
												>
													<RootDesc>
														<div className='flex flex-col'>
															<span>
																<span className='underline'>REP:</span>{' '}
																{message?.request}
															</span>
															<span>
																<span className='underline'>Doctor:</span>{' '}
																{message?.response}
															</span>
														</div>
													</RootDesc>
												</div>
											))}
										</div>
									</Scrollbar>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
