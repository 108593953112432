import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: '',
	reason: '',
	error: '',
	serverStatus: '',
}

const changeRequestSlice = createSlice({
	name: 'change-form',
	initialState,
	reducers: {
		setData(state, action) {
			state.data = action.payload
		},
		setReason(state, action) {
			state.reason = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearCandidate() {
			return initialState
		},
	},
})

export const { setData, setReason, setError, setServerStatus, clearCandidate } =
	changeRequestSlice.actions
export default changeRequestSlice.reducer
