import React from 'react'
import styles from './styles.module.scss'

export const SuccessfulSavePopup = ({ title }) => {
	return (
		<div className={styles.successfulSave}>
			<h2>
				Congratulations, <br /> you have successfully created a {title}!
			</h2>
		</div>
	)
}
