import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const currentYear = moment().year()
const currentMonth = moment().month()
const prevYear = currentYear - 1
const monthsArray = []
const prevMonthsArray = []

for (let month = 0; month < 12; month++) {
	const startDate = moment()
		.year(currentYear)
		.month(month)
		.startOf('month')
		.format('YYYY-MM-DD')

	const endDate = moment()
		.year(currentYear)
		.month(month)
		.endOf('month')
		.format('YYYY-MM-DD')

	const monthName = moment().year(currentYear).month(month).format('MMMM')

	monthsArray.push({
		id: month,
		start_date: startDate,
		end_date: endDate,
		name: `${monthName} ${currentYear}`,
	})
}

for (let month = 0; month < 12; month++) {
	const monthName = moment().year(prevYear).month(month).format('MMMM')

	prevMonthsArray.push({
		id: month,
		name: `${monthName} ${prevYear}`,
	})
}

const getDaysInMonth = (year, month) => {
	return Array.from(
		{ length: moment().year(year).month(month).daysInMonth() },
		(_, i) =>
			moment()
				.year(year)
				.month(month)
				.date(i + 1)
				.format('DD')
	)
}

const initialMonthIndex = currentMonth
const prevMonthIndex = initialMonthIndex === 0 ? 11 : initialMonthIndex - 1

const initialState = {
	months: monthsArray.slice(0, initialMonthIndex + 1),
	thisMonth: monthsArray[initialMonthIndex].name,
	prevMonth: monthsArray[prevMonthIndex].name,
	daysInThisMonth: getDaysInMonth(currentYear, currentMonth),
	daysInPrevMonth: getDaysInMonth(currentYear, prevMonthIndex),
}

const callsSlice = createSlice({
	name: 'calls',
	initialState,
	reducers: {
		setMonths(state, action) {
			state.months = action.payload
		},
		setThisMonth(state, action) {
			const selectedMonth = action.payload
			const monthIndex = monthsArray.findIndex(
				month => month.name === selectedMonth
			)

			if (monthIndex !== -1) {
				state.thisMonth = selectedMonth
				state.daysInThisMonth = getDaysInMonth(currentYear, monthIndex)

				if (monthIndex === 0) {
					state.prevMonth = prevMonthsArray[11].name
					state.daysInPrevMonth = getDaysInMonth(currentYear, 11)
				} else {
					state.prevMonth = monthsArray[monthIndex - 1].name
					state.daysInPrevMonth = getDaysInMonth(currentYear, monthIndex - 1)
				}
			}
		},
	},
})

export const { setMonths, setThisMonth } = callsSlice.actions
export default callsSlice.reducer
