import $api from '../http'

export default class VideosService {
	static async getVideos({ page, size }) {
		return $api.get(`/api/v1/videos?page=${page}&page_size=${size}`)
	}

	static async getAllVideos() {
		return $api.get(`/api/v1/videos`)
	}

	static async viewVideo({ id }) {
		return $api.get(`/api/v1/video/${id}`)
	}

	static async createVideo({ video }) {
		return $api.post(
			`/api/v1/videos`,
			{ ...video },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}

	static async updateVideo({ id, video }) {
		return $api.patch(
			`/api/v1/videos/${id}`,
			{ ...video },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}
}
