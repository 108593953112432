import $api from '../http'

export default class UsersService {
	static async getReps() {
		return $api.get(`/api/v1/reps`)
	}

	static async getManagers() {
		return $api.get(`/api/v1/managers`)
	}

	static async getUsers({ page, size }) {
		return $api.get(`/api/v1/users?page=${page}&page_size=${size}`)
	}

	static async getAllUsers() {
		return $api.get(`/api/v1/users`)
	}

	static async viewUser({ id }) {
		return $api.get(`/api/v1/users/${id}`)
	}

	static async createUser({ user }) {
		return $api.post(
			`/api/v1/users`,
			{ ...user },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}

	static async updateUser({ id, user }) {
		return $api.patch(
			`/api/v1/users/${id}`,
			{ ...user },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
	}
}
