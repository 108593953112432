import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersService from '@/services/UsersService'

export const initialCreateUser = {
	name: '',
	lastname: '',
	email: '',
	password: '',
	role: '',
	product_line_id: '',
	country_id: '',
}

export const postCreateUser = createAsyncThunk(
	'user/create',
	async ({ user }) => {
		try {
			await UsersService.createUser({ user })
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isUserCreate: false,
	saveUserCreate: false,
	error: '',
	serverStatus: '',
	createUser: initialCreateUser,
}

const createUserSlice = createSlice({
	name: 'create-user',
	initialState,
	reducers: {
		setIsUserCreate(state, action) {
			state.isUserCreate = action.payload
		},
		setSaveUserCreate(state, action) {
			state.saveUserCreate = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setCreateUser(state, action) {
			state.createUser = action.payload
		},
		clearCreateUser() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(postCreateUser.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(postCreateUser.fulfilled, (state, action) => {
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(postCreateUser.rejected, (state, action) => {
				state.error = 'Don`t create user!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setIsUserCreate,
	setCreateUser,
	setSaveUserCreate,
	setError,
	setServerStatus,
	clearCreateUser,
} = createUserSlice.actions
export default createUserSlice.reducer
