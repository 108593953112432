import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	viewProduct,
	clearViewProduct,
} from '@/redux/slices/productSlices/viewProductSlice'

import { capitalize } from '@/helpers/helpers'
import { levelOrder } from '@/helpers/constants'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { Spinner } from '@/components//ui/general/Spinner'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { PreviewCard } from '@/components/ui/general/PreviewCard'

import styles from './styles.module.scss'

const ProductField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block w-[280rem] phone:w-[100%]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

const ProductLevelDocuments = ({ level, files }) => (
	<div className={styles.productLevel}>
		<RootDesc>
			<b className='block w-[150rem]'>{capitalize(level)}</b>
		</RootDesc>

		<div className={styles.productDocuments}>
			{files.map(file => (
				<div key={file.id}>
					<SmallDesc>
						<span className={styles.plateDocument}>
							{file.metadata?.filename}
						</span>
					</SmallDesc>
				</div>
			))}
		</div>
	</div>
)

export const ProductViewLayout = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { id } = useParams()

	const { isMobile } = useSelector(state => state.settings)
	const { demo } = useSelector(state => state.candidate)
	const { product, serverStatus } = useSelector(state => state.viewProduct)

	const [groupedFiles, setGroupedFiles] = useState([])

	const productRef = useRef()

	const handleClickEdit = id => {
		navigate(`/admin-panel/products/edit/${id}`, { state: { id } })
	}

	useEffect(() => {
		dispatch(viewProduct({ id }))

		return () => {
			dispatch(clearViewProduct())
		}
	}, [dispatch, id])

	useEffect(() => {
		if (product?.product_version?.files) {
			const filesByLevel = levelOrder.map(level => ({
				level: level.name,
				file: product.product_version.files.filter(
					file => file.level === level.name
				),
			}))

			setGroupedFiles(filesByLevel)
		}
	}, [product])

	useEffect(() => {
		if (productRef.current && isMobile) {
			productRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={productRef} className={styles.productWrapper}>
			<BorderArticle>
				<div className={styles.product}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.productContent}>
							<div className={styles.productContentHeadWrapper}>
								<div className={styles.productContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<h2>{product?.product?.name}</h2>

										{!demo && (
											<ControlButton
												color='var(--blue)'
												txt={'Edit'}
												onClick={() => handleClickEdit(product?.product?.id)}
											/>
										)}
									</div>

									<RootDesc>
										<b>Cover Preview</b>
									</RootDesc>

									<PreviewCard
										name={product?.product?.name}
										mask={product?.product?.mask}
										image={product?.product?.cover}
									/>
								</div>
							</div>

							<ul className={styles.productContentBody}>
								<ProductField
									label='Country'
									value={product?.product?.country}
								/>

								<ProductField
									label='Product Line'
									value={product?.product?.product_line}
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											Related Documents by Difficulty Level
										</b>
									</RootDesc>

									<div className={styles.productLevelList}>
										{groupedFiles.map(item => (
											<ProductLevelDocuments
												key={item.level}
												level={item.level}
												files={item.file}
											/>
										))}
									</div>
								</li>

								<ProductField
									label='The most important questions for training:'
									value={product?.product_version?.questions}
								/>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
