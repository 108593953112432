import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProductsService from '@/services/ProductsService'

export const getProducts = createAsyncThunk(
	'products',
	async ({ page, size }) => {
		try {
			const response = await ProductsService.getProducts({ page, size })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

export const getAllProducts = createAsyncThunk('all-products', async () => {
	try {
		const response = await ProductsService.getAllProducts()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	products: [],
	page: 1,
	size: 5,
	sort: 'Name',
	totalPages: 1,
	error: '',
	serverStatus: '',
}

const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		setPage(state, action) {
			state.page = action.payload
		},
		setSize(state, action) {
			state.size = action.payload
		},
		setSort(state, action) {
			state.sort = action.payload
		},
		setTotalPages(state, action) {
			state.totalPages = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearProducts() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getProducts.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getProducts.fulfilled, (state, action) => {
				state.products = action.payload.data
				state.totalPages = action.payload.total
					? Math.ceil(action.payload.total / state.size)
					: state.totalPages
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getProducts.rejected, state => {
				state.error = 'Don`t get products!'
				state.serverStatus = 'error'
			})

			.addCase(getAllProducts.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getAllProducts.fulfilled, (state, action) => {
				state.products = action.payload.data
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getAllProducts.rejected, state => {
				state.error = 'Don`t get all products!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setPage,
	setSort,
	clearProducts,
	setTotalPages,
	setError,
	setSize,
	setServerStatus,
} = productsSlice.actions
export default productsSlice.reducer
