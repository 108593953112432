import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getProductLine,
	clearProductOptions,
} from '@/redux/slices/productSlices/productOptionsSlice'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { VideosCardLayout } from '../VideosCardLayout'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'

import styles from './styles.module.scss'

export const LibraryLayout = ({ videos }) => {
	const dispatch = useDispatch()

	const { productLine } = useSelector(state => state.productOptions)
	const { role, product_line } = useSelector(state => state.candidate)

	useEffect(() => {
		if (role !== 'Representative') {
			dispatch(getProductLine())
		}

		return () => {
			dispatch(clearProductOptions())
		}
	}, [])

	return (
		<div className='container-big phone:p-0'>
			<div className={styles.videosWrapper}>
				<BorderArticle>
					<div className={styles.videos}>
						<div className={styles.videosHead}>
							<H3>Show Videos</H3>

							<ul>
								<li>
									<RootCheckbox
										onChange={() => console.log('Show All')}
										label={'Show All'}
									/>
								</li>

								{productLine &&
									productLine.length > 0 &&
									productLine.map(line => (
										<li key={line?.id}>
											<RootCheckbox
												onChange={() => console.log(line?.name)}
												label={line?.name}
											/>
										</li>
									))}
							</ul>
						</div>

						<ul className={styles.videosList}>
							{videos &&
								videos.map(item => (
									<li key={item.id}>
										<VideosCardLayout video={item} />
									</li>
								))}
						</ul>
					</div>
				</BorderArticle>
			</div>
		</div>
	)
}
