import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearVideos,
	setPage,
	setTotalPages,
	setSize,
	setSort,
	getVideos,
} from '@/redux/slices/videoSlices/videosSlice'
import moment from 'moment'

import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import TableLayout from '@/components/layouts/TableLayout'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { TableMobileLayout } from '@/components/layouts/TableMobileLayout'

export const Library = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { demo } = useSelector(state => state.candidate)
	const { isMobile } = useSelector(state => state.settings)
	const { videos, page, size, sort, totalPages, serverStatus, error } =
		useSelector(state => state.videos)

	const handleClickAction = async (video, action) => {
		const id = video.id

		if (action === 'view') {
			navigate(`view/${id}`, { state: { id } })
		} else if (action === 'edit') {
			navigate(`edit/${id}`, { state: { id } })
		}
	}

	const handleClickAddNew = () => {
		navigate('create')
	}

	const handleClickDeactivate = video => {
		console.log('Deactivate product with id: ', video.id)
	}

	const handleClickDeactivateAll = () => {
		console.log('Deactivated check videos!')
	}

	const handleCheckFilter = () => {
		console.log('Show inactive videos!')
	}

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const controls = [
		{
			id: 0,
			button: (
				<ControlButton
					color='var(--green)'
					txt={'Add New'}
					onClick={() => handleClickAddNew()}
				/>
			),
		},
		{
			id: 1,
			button: (
				<ControlButton
					color='var(--red)'
					txt={'Deactivate'}
					onClick={() => handleClickDeactivateAll()}
				/>
			),
		},
	]

	const data = useMemo(
		() => [
			{
				id: 0,
				name: 'Handling Doctor Objections',
				product: 'Rolexin',
				line: 'Objections',
				created_at: '2024-10-21',
				inactive: false,
			},
			{
				id: 1,
				name: 'Rolexin: Marketing Strategy C1',
				product: 'Rolexin',
				line: 'Neurology',
				created_at: '2024-10-21',
				inactive: false,
			},
			{
				id: 2,
				name: 'Painxicam: Marketing Strategy C1',
				product: 'Painxicam',
				line: 'Neurology',
				created_at: '2024-10-21',
				inactive: false,
			},
			{
				id: 3,
				name: 'Rolexin: Side Effects and Drug Compatibility',
				product: 'Rolexin',
				line: 'Neurology',
				created_at: '2024-10-21',
				inactive: false,
			},
		],
		[]
	)

	const columns = useMemo(() => {
		const baseColumns = [
			{ Header: 'Name', accessor: 'name', width: 235 },
			{ Header: 'Product', accessor: 'product', width: 350 },
			{ Header: 'Product Line', accessor: 'line', width: 340 },
			{
				Header: 'Created At',
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					const formattedDate = moment(value).format('YYYY-MM-DD')

					return <span>{formattedDate}</span>
				},
				width: 225,
			},
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div
						style={demo ? { justifyContent: 'flex-end' } : {}}
						className='flex gap-[8rem]'
					>
						<ControlButton
							color='var(--orange)'
							txt={'View'}
							onClick={() => handleClickAction(row.original, 'view')}
						/>
						{!demo && (
							<>
								<ControlButton
									color='var(--blueLight)'
									txt={'Edit'}
									onClick={() => handleClickAction(row.original, 'edit')}
								/>
								<ControlButton
									color='var(--red)'
									txt={'Deactivate'}
									onClick={() => handleClickDeactivate(row.original)}
								/>
							</>
						)}
					</div>
				),
				width: 285,
			},
		]

		if (!demo) {
			baseColumns.unshift({
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 35,
			})
		}

		return baseColumns
	}, [demo])

	useEffect(() => {
		dispatch(getVideos({ page, size }))
	}, [page, size, sort])

	useEffect(() => {
		return () => {
			dispatch(clearVideos())
		}
	}, [location, dispatch])

	return (
		// тестовая data
		// <ScreenLayout controlsList={!demo && controls} title={'Training Library'}>
		// 	<TableLayout
		// 		columns={columns}
		// 		data={data}
		// 		filterCheckbox={
		// 			!demo && (
		// 				<RootCheckbox
		// 					onChange={() => alert('filter-table')}
		// 					label={'Show Inactive Videos'}
		// 				/>
		// 			)
		// 		}
		// 	/>
		// </ScreenLayout>

		<ScreenLayout controlsList={!demo && controls} title={'Training Library'}>
			{!isMobile ? (
				<TableLayout
					serverStatus={serverStatus}
					serverError={error}
					nextPage={goToNextPage}
					previousPage={goToPreviousPage}
					totalPages={totalPages}
					dataSize={size}
					currentPage={page}
					toPage={goToPage}
					selectSize={handleSelectSize}
					columns={columns}
					data={data} //videos
					filterCheckbox={
						!demo && (
							<RootCheckbox
								onChange={() => handleCheckFilter()}
								label={'Show Inactive Videos'}
							/>
						)
					}
				/>
			) : (
				<TableMobileLayout
					totalPages={totalPages}
					toPage={goToPage}
					columns={columns}
					data={videos}
					dataSort={sort}
					selectSort={handleSelectSort}
					actions={videos.map(video => (
						<div key={video.id} className='flex gap-[8rem]'>
							<ControlButton
								color='var(--orange)'
								txt={'View'}
								onClick={() => handleClickAction(video, 'view')}
							/>

							{!demo && (
								<>
									<ControlButton
										color='var(--blueLight)'
										txt={'Edit'}
										onClick={() => handleClickAction(video, 'edit')}
									/>

									<ControlButton
										color='var(--red)'
										txt={'Deactivate'}
										onClick={() => handleClickDeactivate(video)}
									/>
								</>
							)}
						</div>
					))}
				/>
			)}
		</ScreenLayout>
	)
}
