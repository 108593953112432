import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ScenariosService from '@/services/ScenariosService'

export const viewScenario = createAsyncThunk(
	'scenario/view',
	async ({ id }) => {
		try {
			const response = await ScenariosService.viewScenario({ id })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	scenario: null,
	error: '',
	serverStatus: '',
}

const viewScenarioSlice = createSlice({
	name: 'view-scenario',
	initialState,
	reducers: {
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearViewScenario() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(viewScenario.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewScenario.fulfilled, (state, action) => {
				state.scenario = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewScenario.rejected, (state, action) => {
				state.error = 'Don`t view scenario!'
				state.serverStatus = 'error'
			})
	},
})

export const { clearViewScenario, setError, setServerStatus } =
	viewScenarioSlice.actions
export default viewScenarioSlice.reducer
