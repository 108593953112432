import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Header } from '@/components/layouts/Header'
import { Controls } from './Controls'
import { Chat } from './Chat'
import { RootButton } from '@/components/ui/buttons/RootButton'
import AvatarComponent from './AvatarComponent'
import AvatarImage from './AvatarImage'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const ChatGame = () => {
	const { isMobile } = useSelector(state => state.settings)
	const [showAvatar, setShowAvatar] = useState(false)
	const { error } = useSelector(state => state.avatar)

	return (
		<div className={styles.gameWrapper}>
			<Header />

			<div className='container-big phone:p-0'>
				<div className={styles.game}>
					<div className={styles.doctor}>
						{window.location.host.includes('test') ||
						window.location.host.includes('localhost') ? (
							<>
								<div className={styles.btn}>
									<RootButton
										onClick={() => setShowAvatar(!showAvatar)}
										txt={
											isMobile ? (
												<RootIcon width={24} height={24} id={'show-pass'} />
											) : (
												'Show/Hide Avatar'
											)
										}
									/>
								</div>

								{showAvatar ? (
									error === '' ? (
										<>
											<AvatarComponent />
											<Controls />
										</>
									) : (
										<AvatarImage />
									)
								) : (
									<>
										<AvatarImage />
									</>
								)}
							</>
						) : error === '' ? (
							<>
								<AvatarComponent />
								<Controls />
							</>
						) : (
							<AvatarImage />
						)}

						{/* для тестов - без аватара */}
						{/* <>
							<AvatarComponent />
							<Controls />
						</> */}
					</div>

					<div className='relative z-10 bottom-[50rem] phone:w-[100%] phone:bottom-0 phone:flex phone:items-center justify-center'>
						<Chat />
					</div>
				</div>
			</div>
		</div>
	)
}
