import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProductsService from '@/services/ProductsService'

export const viewProduct = createAsyncThunk('product/view', async ({ id }) => {
	try {
		const response = await ProductsService.viewProduct({ id })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	product: null,
	error: '',
	serverStatus: '',
}

const viewProductSlice = createSlice({
	name: 'view-product',
	initialState,
	reducers: {
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearViewProduct() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(viewProduct.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewProduct.fulfilled, (state, action) => {
				state.product = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewProduct.rejected, (state, action) => {
				state.error = 'Don`t view product!'
				state.serverStatus = 'error'
			})
	},
})

export const { clearViewProduct, setError, setServerStatus } =
	viewProductSlice.actions
export default viewProductSlice.reducer
