import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearProductOptions,
	getProductLine,
} from '@/redux/slices/productSlices/productOptionsSlice'
import {
	clearScenarios,
	getAllScenarios,
} from '@/redux/slices/scenarioSlices/scenariosSlice'
import {
	clearProducts,
	getAllProducts,
} from '@/redux/slices/productSlices/productsSlice'

import { SimulationsLayout } from '@/components/layouts/SimulationsLayout'
import { Spinner } from '@/components//ui/general/Spinner'

import styles from './styles.module.scss'

export const Call = () => {
	const dispatch = useDispatch()

	const statusProducts = useSelector(state => state.products.serverStatus)
	const statusScenarios = useSelector(state => state.scenarios.serverStatus)

	const { scenarios } = useSelector(state => state.scenarios)
	const { products } = useSelector(state => state.products)

	useEffect(() => {
		dispatch(getAllScenarios())
		dispatch(getAllProducts())

		return () => {
			dispatch(clearScenarios())
			dispatch(clearProducts())
		}
	}, [dispatch])

	const productMap = {}
	const productLineMap = {}

	products.forEach(product => {
		productMap[product.name] = product.product_line_id
		productLineMap[product.product_line_id] = product.product_line
	})

	const allScenarios = scenarios
		.filter(scenario => {
			const scenarioProducts = scenario.products
				.split(', ')
				.map(product => product.trim())

			return scenarioProducts.some(product => productMap[product])
		})
		.map(scenario => {
			const scenarioProducts = scenario.products
				.split(', ')
				.map(product => product.trim())

			const productLines = [
				...new Set(
					scenarioProducts.map(product => productLineMap[productMap[product]])
				),
			]

			return {
				...scenario,
				product_line: productLines,
			}
		})

	const transformScenarios = allScenarios.map(item => ({
		id: item.id,
		name: item.product_line[0],
		scenarios: allScenarios.filter(scenario =>
			scenario.product_line.includes(item.product_line[0])
		),
	}))

	const uniqueTransformScenarios = []
	const uniqueNames = new Set()

	transformScenarios.forEach(item => {
		if (!uniqueNames.has(item.name)) {
			uniqueNames.add(item.name)
			uniqueTransformScenarios.push(item)
		}
	})

	uniqueTransformScenarios.sort((a, b) => a.name.localeCompare(b.name))

	return (
		<div className='container-big'>
			<div className={styles.call}>
				{statusProducts === 'loading' || statusScenarios === 'loading' ? (
					<Spinner width={80} height={80} />
				) : (
					<ul>
						{uniqueTransformScenarios &&
							uniqueTransformScenarios.map(item => (
								<li key={item.id}>
									<SimulationsLayout title={item.name} cards={item.scenarios} />
								</li>
							))}
					</ul>
				)}
			</div>
		</div>
	)
}
