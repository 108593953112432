import React, { useState, useEffect, useRef } from 'react'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Scrollbar } from '../../general/Scrollbar'

import styles from './styles.module.scss'

export const RootTextarea = props => {
	const { label, type, value, required, onChange, disabled, placeholder } =
		props

	const [textareaHeight, setTextareaHeight] = useState(`32rem`)
	const textareaRef = useRef(null)

	const handleChange = event => {
		onChange(event)
		setTextareaHeight('auto')
		setTextareaHeight(`${textareaRef.current.scrollHeight}rem`)
	}

	useEffect(() => {
		setTextareaHeight(
			value ? `${textareaRef.current.scrollHeight}rem` : `32rem`
		)
	}, [value])

	return (
		<label className={styles.rootInput}>
			{label && (
				<RootDesc>
					<b className='phone:block phone:w-[100%]'>{label}</b>
				</RootDesc>
			)}

			<div className={styles.textarea}>
				<textarea
					ref={textareaRef}
					placeholder={placeholder}
					type={type}
					value={value}
					onChange={handleChange}
					aria-label={label}
					required={required}
					disabled={disabled}
					style={{ height: textareaHeight }}
				/>
			</div>
		</label>
	)
}
