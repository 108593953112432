import React from 'react'
import styles from './styles.module.scss'

export const BrowserSupportPopup = () => {
	return (
		<div className={styles.browserPopup}>
			<h2>
				Your browser does not support voice recording <br />
				and transcription!
			</h2>

			<h2>Please use Google Chrome or Safari browsers</h2>
		</div>
	)
}
