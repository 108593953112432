import React from 'react'
import styles from './styles.module.scss'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { useSelector } from 'react-redux'

export const RootInput = props => {
	const {
		width,
		label,
		type,
		value,
		required,
		onChange,
		disabled,
		placeholder,
	} = props

	const { isMobile } = useSelector(state => state.settings)

	return (
		<label className={styles.rootInput}>
			<RootDesc>
				<b className='phone:block phone:w-[100%]'>{label}</b>
			</RootDesc>

			<RootDesc>
				<input
					placeholder={placeholder}
					type={type}
					value={value}
					onChange={onChange}
					aria-label={label}
					required={required}
					disabled={disabled}
					style={isMobile ? { width: '100%' } : { width: `${width}rem` }}
				/>
			</RootDesc>
		</label>
	)
}
